import styled from 'styled-components';

const SectionTitleDesc = styled.p`
  color: ${props => props.theme.colors.white};
  opacity: .3;
  font-size: 1rem;
  text-align: center;
  padding-top: 1rem;
  font-weight: 300;
`;

export default SectionTitleDesc;