import inwestycje from './inwestycje.png';
import kredytFirmowy from './kredyt-firmowy.png';
import kredytGotowkowy from './kredyt-gotowkowy.png';
import kredytHipo from './kredyt-hipoteczny.png';
import oszczedzanie from './oszczedzanie.png';
import ubezpieczenia from './ubezpieczenia.png';

export const servicesImages = {
    "kredyt-hipoteczny" :kredytHipo,
    "kredyt-firmowy" :kredytFirmowy,
    "kredyt-gotowkowy" :kredytGotowkowy,
    "ubezpieczenia" :ubezpieczenia,
    "oszczedzanie" :oszczedzanie,
    "inwestycje" :inwestycje
};