import { useRef } from 'react';

export default function () {
    const setRotation = (element, pos) => {
        const style = `rotateX( ${pos.y}deg) rotateY( ${pos.x}deg)`;
        element.style.transform = style;
        element.style.webkitTransform = style;
        element.style.mozTransform = style;
        element.style.msTransform = style;
        element.style.oTransform = style;
    };
    const setBoxShadow = (element, pos) => {
        const style = `${pos.x}px ${pos.y}px 10px rgba(0,0,0,${.5 - (Math.abs(pos.x) + Math.abs(pos.y))/100})`;
        element.style.boxShadow = style;
        element.style.webktitBboxShadow= style;
        element.style.mozBboxShadow = style;
        element.style.msBboxShadow = style;
        element.style.oBboxShadow = style;
    };
    const contentRef = useRef(null);
    const onMouseMoveHandler = (e) => {
        let counter = 0;
        const updateRate = 20;
        const isTimeToUpdate = () => {
            return counter++ % updateRate === 0;
        };
        if (isTimeToUpdate()) {
            const container = contentRef.current;
            const client = container.getBoundingClientRect();
            const mouse = {
                x: (e.clientX - client.x - client.width / 2),
                y: ((e.clientY - client.y - client.height / 2) * -1)
            };

            const setStylePos = {
                rotate: {
                    x: parseFloat((mouse.x / client.width / 2).toFixed(2)),
                    y: parseFloat((mouse.y / client.height / 2).toFixed(2))
                }
            };
            setStylePos['boxShadow'] = {
                x: setStylePos.rotate.x * -100,
                y: setStylePos.rotate.y * 100
            };
            setRotation(container, setStylePos.rotate);
            setBoxShadow(container, setStylePos.boxShadow);
        }
    };

    const onMouseLeaveHandler = () => {
        const container = contentRef.current;
        setRotation(container, {x: 0, y: 0});
        setBoxShadow(container, {x: 0, y: 0});
    };

    return {
        ref: contentRef,
        onMouseMove: onMouseMoveHandler,
        onMouseLeave: onMouseLeaveHandler
    }
};