export default {
    colors: {
        primary: `#6A5095`,
        darkPrimary: `#331E56`,
        lightPrimary: `#B89CEB`,
        primaryBg: `#F9F6FF`,
        black: `#000000`,
        white: `#FFFFFF`,
        secondary: `#00FFA8`,
        darkSecondary: `#00D98F`,
        error: `#870000`,
    },
    sizes: {
        nav: {
            height: `80px`
        },
        footer: {
            height: `60px`
        }
    }
}
