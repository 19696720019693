import React from 'react';
import { Link as BaseLink } from '../content/Link';
import styled from "styled-components";

const Link = styled(BaseLink)`
  font-size: 1.4em;
`;

const EmailAddress = ({email}) => {
    return (
        <Link href={`mailto:${email}`}>{email}</Link>
    );
};

export default EmailAddress;
