import styled from 'styled-components';

const AccentButton = styled.div`
  background: ${props => props.theme.colors.secondary};
  transition: .2s all ease-in;
  text-align: ${props => props.left ? 'left' : 'center'};
  padding: .3rem ${props => props.left ? '1.5rem' : `.5rem`} .3rem .5rem;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.colors.darkPrimary};
  &:hover{
    cursor: pointer;
    background: ${props => props.theme.colors.darkPrimary};  
    color: ${props => props.theme.colors.white};
  }
`;

export default AccentButton;