import React from 'react';
import Anime from "react-anime";

export default ({anime}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.1922mm" height="43.1922mm" viewBox="0 0 3515 3515">
            <g id="Warstwa_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer"/>
                    <path className="fill" d="M1758 0c485,0 924,197 1243,515 318,318 514,757 514,1243 0,485 -196,924 -514,1243 -319,318 -758,514 -1243,514 -486,0 -925,-196 -1243,-514 -318,-319 -515,-758 -515,-1243 0,-486 197,-925 515,-1243 318,-318 757,-515 1243,-515zm1150 607c-294,-295 -701,-477 -1150,-477 -450,0 -857,182 -1151,477 -295,294 -477,701 -477,1151 0,449 182,856 477,1150 294,295 701,477 1151,477 449,0 856,-182 1150,-477 295,-294 477,-701 477,-1150 0,-450 -182,-857 -477,-1151z"/>
                    <Anime
                        d={[
                            {
                                value: "M1297 222c55,0 100,671 100,1498 0,4 0,8 0,13l0 0c0,-5 0,-9 0,-13 0,-656 -67,-657 -67,-1 0,-656 -66,-656 -66,0 0,-648 -66,-660 -67,-13 1,-821 45,-1484 100,-1484z",
                                duration: 800,
                                delay: 300
                            },
                            {
                                value: "M1758 801c584,0 1058,474 1058,1059 0,3 0,6 0,10l0 0c0,-4 0,-7 0,-10 0,-464 -705,-464 -705,-1 -1,-463 -706,-463 -706,0 -1,-458 -694,-466 -706,-8 5,-581 477,-1050 1059,-1050z",
                                duration: 800,
                                delay: 300
                            }
                        ]}
                        translateX={[
                            {
                                value: 460,
                                duration: 800,
                                delay: 300
                            },
                            {
                                value: 0,
                                duration: 800,
                                delay: 300
                            }
                        ]}
                        translateY={[
                            {
                                value: 400,
                                duration: 800,
                                delay: 300
                            },
                            {
                                value: 0,
                                duration: 800,
                                delay: 300
                            }
                        ]}
                        duration={3900}
                        loop={true}
                        direction={`normal`}
                        easing={`easeOutElastic`}
                        key={Date.now() + '_saving_rotate'}
                        autoplay={anime}
                    >
                        <path className={`fill_primary stroke`} fill="none" strokeWidth="81.3881" d="M1758 801c584,0 1058,474 1058,1059 0,3 0,6 0,10l0 0c0,-4 0,-7 0,-10 0,-464 -705,-464 -705,-1 -1,-463 -706,-463 -706,0 -1,-458 -694,-466 -706,-8 5,-581 477,-1050 1059,-1050z"/>
                    </Anime>
                <path className={`fill`} fillRule="nonzero" d="M1793 1512l0 952c0,2 0,3 0,4 -2,120 -76,195 -172,228 -43,14 -90,20 -136,17 -47,-2 -93,-14 -135,-33 -100,-47 -174,-141 -161,-280 2,-22 22,-39 44,-37 22,3 39,22 37,45 -10,98 43,165 115,198 32,15 68,24 105,26 36,2 72,-2 105,-13 65,-22 115,-72 117,-152 0,-1 0,-2 0,-3l0 -952 81 0z"/>
            </g>
        </svg>
    )
}
