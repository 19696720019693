import React from 'react';
import GradientBGContainer from '../components/container/GradientBGContainer';
import WhiteBGContainer from '../components/container/WhiteBGContainer';
import Header from "../components/header/Header";
import ServicesSection from "../components/servicesSection/ServicesSection";
import WizardSection from "../components/wizardSection/WizardSection";
import OpinionsContainer from "../components/Opinions/OpinionsContainer";
import HomeFooter from "../components/footer/HomeFooter";
import {useTranslation} from "react-i18next";
import Helmet from "../components/helmet/helmet";
import withGACurrentPageview from "../hoc/withGACurrentPageview";

const HomeScreen = () => {

    const { t } = useTranslation();
    const opinions = t('sections.opinions', { returnObjects: true });
    const helmet = t('helmet', { returnObjects: true });
    return (
        <>
            <Helmet helmet={helmet} />
            <GradientBGContainer>
                <Header />
                <ServicesSection />
                <WizardSection />
            </GradientBGContainer>
            <WhiteBGContainer>
                <OpinionsContainer opinions={opinions}/>
                <HomeFooter />
            </WhiteBGContainer>
        </>
    );
};

export default withGACurrentPageview(HomeScreen);
