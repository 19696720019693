import React from 'react';
import styled from 'styled-components';

const AccentField = styled.div`
  background: ${props => props.revert ? props.theme.colors.primary : props.theme.colors.secondary};
  color: ${props => props.revert ? props.theme.colors.secondary : props.theme.colors.primary};
  font-weight: 900;
  font-size: ${props => props.fontSize ? props.fontSize : '1.2rem'};
  padding: 0 0 0 .6rem;
  line-height: 32px;
  width: auto;
  display: block;
  > span{
     background: ${props => props.revert ? props.theme.colors.secondary : props.theme.colors.primary};
     color: ${props => props.revert ? props.theme.colors.primary : props.theme.colors.secondary};
     font-size: .8rem;
     line-height: 32px;
     padding: 0 1rem;
     margin-left: 1rem;
     display: inline-block;
     float: right;
     cursor: pointer;
     transition: .2s all ease-in;
     letter-spacing: .1rem;
     font-weight: normal;
     &:hover{
      background: ${props => props.revert ? props.theme.colors.darkSecondary : props.theme.colors.darkPrimary};
      color: ${props => props.revert ? props.theme.colors.darkPrimary : props.theme.colors.darkSecondary};
     }
  }
  > a{
    color: ${props => props.revert ? props.theme.colors.darkSecondary : props.theme.colors.darkPrimary};
  }
`;

export const AccentComponent = ({ children }) => {
    return (
        <AccentField>{ children }</AccentField>
    );
};
