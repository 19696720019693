import React from 'react';
import {fab} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.primary};;
`;

const FooterSocialElement = ({social}) => {
    return (
        <li>
            <a href={social.link} target={'_blank'}>
                <Icon size="lg" icon={fab[social.icon]} />
            </a>
        </li>
    );
};

export default FooterSocialElement;
