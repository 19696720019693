import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import AppContainer from "./router/AppContainer";

import './i18n';
import {useTranslation} from "react-i18next";
import AppLoader from "./components/preloaders/AppLoader";
import primary from "./theme/primary";
import {ThemeProvider} from "styled-components";
import ReactGA from "react-ga";

if (process.env.BROWSER) {
    ReactGA.initialize('UA-51817330-7');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = () => {

    const { ready } = useTranslation();
    const [render, setRender] = useState([<AppLoader key={'loader'} disappear={ready}/>]);
    useEffect(() => {
        if (ready === true) {
            setRender([ <AppLoader key={'loader'} disappear={ready}/>, <AppContainer key={'app'}/> ])
        }
    }, [ready]);

    return (
        <ThemeProvider theme={primary}>
            <>
                { render }
            </>
        </ThemeProvider>
    )
};

export default App;
