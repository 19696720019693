import styled from 'styled-components';
import { Container } from 'react-bootstrap'

const GlobalContent = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.sizes.nav.height} 0 0 0;
  background: ${props => props.theme.colors.primaryBg};
  min-height: calc(100vh - ${props => props.theme.sizes.footer.height});
`;

export default GlobalContent;