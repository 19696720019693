import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Carousel, Col} from 'react-bootstrap';
import media from '../../theme/mediaQuery';
import { getOpinionsRequest } from '../../api/actions/getOpinionsRequest'

const Container = styled.div`
  margin-top: -8rem;
  background: ${props => props.theme.colors.primary};
  width: 90%;
  min-height: 450px;
  margin-left: 5%;
  margin-bottom: 5%;
  padding: 0 2rem;
  @media ${media.sm} {
      margin-left: 1%;
      margin-bottom: 1%;
      padding: 0;
      width: 98%;
  }
  text-align: center;
`;

const CarouselContainer = styled(Carousel)`
  height: 100%;
  position: relative; 
  > div.carousel-inner{
    height: 75%;
    > div.carousel-item {
      height: 100%;
    }
  }
  ol.carousel-indicators{
    bottom: 2rem;
    > li{
      width: 10px;
      height: 10px;
      padding: 0;
      border: 0;
      border-radius: 50%;
        background: ${props => props.theme.colors.lightPrimary};
        &.active{
          background: ${props => props.theme.colors.secondary};
        }
    }
  }
`;

const Caption = styled(Carousel.Caption)`
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
  padding: 6rem 1rem;
  height: 100%;
  > h5{
    color: ${props => props.theme.colors.white};
    opacity: .3;
    padding-bottom: 2rem;
    transition: .2s all ease-in-out;
  }
  > p{
    text-align: center;
    font-style: italic;
    padding: 1rem;
  }
  > p.date{
      text-align: center;
      font-size: .9rem;
  }
`;

const OpinionsContainer = () => {

    const interval = 5000;
    const [activeIndex, setActiveIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };
    const [opinions, setOpinions] = useState([]);

    useEffect(() => {
        getOpinionsRequest().then(({data}) => {
            setOpinions(data);
        });
    }, [])

    const getDate = (date) => {
        const parsedDate = new Date(date);
        const formattedDate = parsedDate.toLocaleString("pl-PL", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
        });
        return formattedDate;
    };

    return (
        <Container>
            <CarouselContainer
                activeIndex={activeIndex}
                direction={`left`}
                onSelect={handleSelect}
                controls={false}
                fade={true}
                interval={interval}
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
            >
                {
                    opinions.map(o => (
                        <Carousel.Item key={o.id}>
                            <Col
                                sm={{span: 12}}
                                md={{span: 8, offset: 2}}
                                lg={{span: 6, offset: 3}}
                            >
                                <Caption>
                                    <h5>{o.masked_author}</h5>
                                    <p>{o.content}</p>
                                    <p className={"date"}>{getDate(o.date_added)}</p>
                                </Caption>
                            </Col>
                        </Carousel.Item>
                    ))
                }
            </CarouselContainer>
        </Container>
    );
};

export default OpinionsContainer;
