import React, { createRef, useState } from 'react';
import Accents from "../content/Accents";
import styled, {withTheme} from "styled-components";
import media from '../../theme/mediaQuery';

const HalfFullWidthImageContainer = styled.div`
  width: 50vw;
  @media ${media.lg} {
    width: 100%;
    margin: 20px 0;
  }
  position: relative;
  float: left;
  div.image-container{
    background: ${props => props.theme.colors.primary}; 
    float: left;
    position: relative;
    z-index: 100;
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      z-index: 100;
      position: relative;
      display: block;
      float: left;
      @media ${media.md} {
        width: 100%;
        left: 0;
      }
    }
  }
`;

const ImagePreloader = styled.div`
  width: 50vw;
  @media ${media.lg} {
    width: 100%;
  }
  height: auto;
  background: ${props => props.theme.colors.lightPrimary};
  left: 0;
  opacity: .4;
`;

const DecorateImage = ({image, theme, accentSettings, fullImage, decorate = true}) => {

    const ElementRef = createRef();
    const [element, setElement] = useState(null);
    const imageFull = fullImage === undefined || fullImage !== false;

    const onLoad = () => {
        setElement(ElementRef.current);
    };

    return (
        <HalfFullWidthImageContainer
            full={imageFull}
        >
            {
                <div className={`image-container`}>
                    <img
                        src={image}
                        alt={`Agniesz Łuczak`}
                        ref={ElementRef}
                        onLoad={onLoad}
                    />
                    <ImagePreloader />
                </div>
            }
            {
                decorate && (
                    <Accents
                        parent={element}
                        position={[`left-bottom`]}
                        color={theme.colors.darkSecondary}
                        {...accentSettings}
                    />
                )
            }
        </HalfFullWidthImageContainer>
    );
};

export default withTheme(DecorateImage);
