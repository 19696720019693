import React from 'react';
import {withTheme} from "styled-components";

const FirstTalk = ({ anime, theme }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80.6665mm" height="74.8342mm" viewBox="0 0 15777 14636">
            <g id="Warstwa_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer"/>
                <g id="phone">
                    <path className="str0"
                          d="M5899 3877l1048 1063c63,65 59,166 -2,233l-858 929c-37,40 -91,54 -142,45 -1941,-134 -4267,-2399 -4483,-4434 -1,-6 -2,-13 -2,-20 -3,-47 16,-94 47,-129l858 -928c62,-67 169,-63 233,1l1047 1064c64,64 60,166 -1,232l-609 659c2,429 1583,1932 2025,1940l607 -657c61,-66 169,-63 232,2z"/>
                    <path className="fil1"
                          d="M4677 1508c-105,-23 -171,-128 -148,-233 24,-105 128,-171 233,-147 446,101 802,303 1080,594 274,286 467,655 592,1095 30,104 -30,212 -134,241 -104,30 -212,-30 -242,-134 -108,-380 -271,-695 -499,-933 -224,-235 -515,-399 -882,-483z"/>
                    <path className="fil1"
                          d="M7559 2780c25,105 -41,210 -146,234 -105,24 -210,-42 -234,-147 -156,-674 -420,-1233 -813,-1653 -391,-417 -914,-700 -1593,-827 -106,-20 -177,-121 -157,-227 19,-106 121,-176 227,-157 766,143 1360,467 1807,943 443,473 738,1093 909,1834z"/>
                </g>
                <g id="confirm_cloude">
                    <path className="fil1"
                          d="M11385 7331c-85,-65 -102,-188 -36,-274 65,-85 188,-102 273,-36l464 353 1101 -1297c69,-82 193,-93 275,-23 82,70 92,193 23,275l-1222 1439c-66,79 -184,92 -267,29l-611 -466z"/>
                    <path className="fil1"
                          d="M12456 4000c912,0 1739,328 2339,860 607,537 982,1281 982,2104 0,822 -375,1566 -982,2103 -600,532 -1427,861 -2339,861 -264,0 -522,-28 -771,-81 -255,-54 -499,-134 -727,-237 -47,-21 6,46 -51,81l0 0 0 1c-245,150 -627,386 -1165,236 -104,-29 -165,-136 -136,-240 5,-18 12,-34 21,-49 36,-66 94,-147 160,-238 119,-167 361,-305 318,-344 -305,-273 -551,-598 -718,-959 -163,-350 -252,-733 -252,-1134 0,-823 375,-1567 982,-2104 600,-532 1427,-860 2339,-860zm2081 1152c-531,-470 -1267,-761 -2081,-761 -814,0 -1550,291 -2081,761 -525,465 -849,1105 -849,1812 0,344 77,671 215,970 146,313 359,595 624,832 206,185 -25,532 -226,814 236,-20 427,-138 564,-222l0 1c159,-98 275,-169 415,-105 204,92 421,163 648,211 220,47 451,72 690,72 814,0 1550,-291 2081,-762 525,-464 849,-1105 849,-1811 0,-707 -324,-1347 -849,-1812z"/>
                </g>
                <g id="dialogue">
                    <circle className="fil2" cx="3765" cy="10853" r="225"/>
                    <circle className="fil2" cx="4487" cy="10911" r="225"/>
                    <circle className="fil2" cx="5196" cy="10951" r="225"/>
                    <path className="fil1"
                          d="M2246 12344l2913 226c61,5 113,37 145,83l614 756 198 -602c22,-85 98,-147 190,-147 8,0 16,0 25,2l340 26c11,1 9,1 12,1 72,3 138,-23 187,-67 49,-43 81,-105 87,-177l0 -1 0 0c0,-11 1,-8 1,-12l92 -2690c2,-81 -28,-158 -80,-216 -52,-57 -124,-96 -204,-102l-4424 -344c-11,-1 -9,-1 -12,-1 -72,-3 -138,23 -187,66 -49,44 -81,106 -87,178l0 1 0 0c0,11 -1,8 -1,11l-92 2691c-3,81 28,157 80,215 51,58 124,97 203,103zm2800 610l-2829 -220c-184,-15 -348,-103 -464,-233 -117,-130 -186,-303 -179,-487l92 -2691 0 -19 1 -6 0 -5 0 0c14,-175 95,-329 216,-438 122,-109 284,-172 459,-166 17,1 27,1 29,1l4425 344c184,14 348,102 464,233 117,130 186,303 179,487l-92 2691 0 19 -1 6 0 5 0 0c-14,175 -95,329 -216,438 -122,109 -284,172 -459,166 -17,-1 -27,-1 -29,-1l-199 -16 -265 808 0 0c-11,35 -32,67 -62,91 -84,68 -207,56 -275,-28l-795 -979z"/>
                    <path className="fil1"
                          d="M1091 9644c108,8 189,102 181,210 -8,107 -102,188 -210,180l-293 -23c-11,-1 -9,-1 -12,-1 -73,-2 -138,23 -187,67 -49,43 -81,106 -87,177l0 1 0 0c-1,11 -1,8 -1,12l-92 2691c-3,80 28,157 80,215 51,57 123,96 203,103l270 21c103,7 181,94 180,196l26 541 710 -609c40,-35 91,-50 141,-46l0 0 3097 240c108,8 189,102 181,210 -8,107 -102,188 -210,180l-3017 -234 -951 815 0 0c-32,27 -73,44 -118,46 -108,5 -199,-78 -204,-185l-37 -778 -97 -8c-184,-14 -348,-102 -465,-233 -116,-130 -185,-303 -179,-487l92 -2690 1 -20 0 -6 1 -5 0 0c13,-175 94,-329 216,-438 122,-108 283,-172 459,-166 17,1 27,1 29,2l293 22z"/>
                </g>
            </g>
        </svg>
    )
};

export default withTheme(FirstTalk);
