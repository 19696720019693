import React from 'react';
import {Container as BaseContainer} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import {ContactRow} from "../components/content/ContactRow";
import EmailAddress from "../components/content/EmailAddress";
import GoogleMapReact from 'google-map-react';
import {googleMapStyles} from "../theme/googleMapStyles";
import BoldText from "../components/typography/BoldText";
import Helmet from "../components/helmet/helmet";
import Marker from "../components/map/Marker";
import withGACurrentPageview from "../hoc/withGACurrentPageview";

const AccentField = styled.span`
  text-align: left;
  color: ${props => props.theme.colors.darkPrimary};
  background: ${props => props.theme.colors.secondary};
  padding: .2rem 3rem;
  font-weight: 900;
  font-size: 2rem;
  > a {
      color: ${props => props.theme.colors.darkPrimary};
  } 
`;

const Container = styled(BaseContainer)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: calc(100vh - ${props => props.theme.sizes.nav.height} - ${props => props.theme.sizes.footer.height});
`;


const ContactScreen = () => {

    const mapOptions = {
        panControl: false,
        mapTypeControl: false,
        zoomControl: true,
        scrollwheel: true,
        draggable: false,
        styles: googleMapStyles
    };

    const {t} = useTranslation();
    const helmet = t('contact.helmet', {returnObjects: true});
    const phoneNumber = t('contact.phoneNumber');
    return (
        <Container>
            <Helmet helmet={helmet}/>
            <ContactRow title={t('contact.callMe')}>
                <AccentField>
                    {t('contact.tel')} <a href={"tel:"+ phoneNumber}>{phoneNumber}</a>
                </AccentField>
            </ContactRow>
            <ContactRow title={t('contact.textMe')}>
                <EmailAddress email={t('contact.email')}/>
            </ContactRow>
            <ContactRow title={t('contact.findMe')}>
                <BoldText
                    dark
                    fontSize={`1.6rem`}
                >
                    Biuro - ONYX Business Point (II piętro)
                    <br/>
                    <small>(II piętro)</small>
                </BoldText>
                <br/>
                <br/>
                <BoldText
                    dark
                    fontSize={`2rem`}
                    lineHeight={`2.1rem`}
                >
                    ul. Witaszka 6 <br/>
                    64-920 Piła
                </BoldText>
            </ContactRow>
            <ContactRow
                height={`300px`}
                accent
            >
                <GoogleMapReact
                    bootstrapURLKeys={{key: `AIzaSyDJT_Rlrro5SWMj05FVJQ7IggsJXuKUHoQ`}}
                    zoom={16}
                    defaultCenter={{
                        lat: 53.146914,
                        lng: 16.741857
                    }}
                    options={mapOptions}
                >
                    <Marker
                        lat={53.146914}
                        lng={16.741857}
                        title={"Agnieszka Łuczak - Onyx Business Point, ul. Witaszka 6, Piła"}
                        draggable={false}
                    />
                </GoogleMapReact>
            </ContactRow>
        </Container>
    );
};

export default withGACurrentPageview(ContactScreen);
