import companyCredit from './company_credit.js';
import houseCredit from './house_credit.js';
import invest from './invest.js';
import moneyCredit from './monay_credit.js';
import health from './safety.js';
import saving from './saving.js';

export default {
    companyCredit,
    houseCredit,
    invest,
    moneyCredit,
    health,
    saving,
}