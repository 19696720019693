import React from 'react';
import styled from 'styled-components';
import Anime from "react-anime";

const Container = styled.div`
  margin-top: 5vh;
  opacity: .3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary}
`;

const Triangle = styled.div`
  width: 0; 
  height: 0; 
  opacity: 1;
  border-left: ${props => props.size /1.5}px solid transparent;
  border-right: ${props => props.size / 1.5}px solid transparent;
  border-top: ${props => props.size}px solid ${props => props.theme.colors.primary};
`;

const TriangleText = styled.span`
  font-size: .6rem;
  color: inherit;
  text-transform: uppercase;
`;

const ScrollIndicator = () => {

    const animeProps = {
        translateY: [{value: 7}],
        opacity: [{value: 1}, {value: 0}],
        scaleX: [1, .8],
        scaleY: [1, .8],
        loop: true,
        delay: 0,
        duration: 4000
    };

    return (
        <Container>
            <TriangleText>Scroll</TriangleText>
            <Anime key={Date.now() + 'scroll_indicator'} {...animeProps} >
                <Triangle size={6}/>
            </Anime>
        </Container>
    );
};

export default ScrollIndicator;