import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import styled from 'styled-components';
import HomeFooterDesc from "./HomeFooterDesc";
import HomeFooterContactCol from "./HomeFooterContactCol";
import Text from "../typography/Text";
import {useTranslation} from "react-i18next";

const ContainerHomeFooter = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 5rem;
  div.HomeFooterContactCol{
    div.FooterAccentButton{
      margin-bottom: 3rem; 
    }
   >p{
    text-align: left;
    font-weight: 700;
    &.phone{
      font-size: 1.4rem;
    }
    > small{
      font-weight: 300;
    }
    > a {
      color: ${props => props.theme.colors.primary};
      transition: .2s color ease-in;
      text-decoration: none;
      &:hover{
        color: ${props => props.theme.colors.secondary};
      }
    }
   }
  }
`;

const HomeFooter = () => {

    const {t} = useTranslation();
    const phoneNumber = t('contact.phoneNumber');

    return (
        <ContainerHomeFooter>
            <Row>
                <Col lg={5}>
                    <HomeFooterDesc />
                </Col>
                <Col className={`HomeFooterContactCol`}>
                    <HomeFooterContactCol
                        title={`Masz pytania?`}
                    >
                        <Text className={`phone`}>
                            <a href={"tel:" + phoneNumber}> tel. {phoneNumber}</a>
                        </Text>
                        <Text><a href={`mailto:luczakkredyty@gmail.com`}>luczakkredyty@gmail.com</a></Text>
                    </HomeFooterContactCol>
                </Col>
                <Col className={`HomeFooterContactCol`}>
                    <HomeFooterContactCol
                        title={`Poznajmy się!`}
                    >
                        <Text>
                            Biuro - ONYX Business Point
                            <br /><small>(II piętro)</small>
                        </Text>
                        <Text>
                            ul. Witaszka 6<br />
                            64-920 Piła
                        </Text>
                    </HomeFooterContactCol>
                </Col>
            </Row>
        </ContainerHomeFooter>
    );
};

export default HomeFooter;
