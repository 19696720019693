import React from 'react';
import {Helmet as HelmetHelper} from "react-helmet";

const Helmet = ({helmet}) => {
    return (
        <HelmetHelper>
            <title>{helmet.title}</title>
            <meta name="description" content={helmet.desc} />
        </HelmetHelper>
    );
};

export default Helmet;
