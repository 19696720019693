import React from 'react';
import styled from 'styled-components';

const SectionContainerStyled = styled.section`
  margin: 5rem 0 2rem;
  padding: 2rem 0;
`;

const SectionContainer = ({children}) => {

    return (
        <SectionContainerStyled>
            { children }
        </SectionContainerStyled>
    );
};

export default SectionContainer;