import styled from 'styled-components';

const SectionTitle = styled.h2`
  color: ${props => props.theme.colors.white};
  font-size: 1.8rem;
  padding: 2vh 5vw;
  margin-bottom: 2rem;
  text-align: center;
`;

export default SectionTitle;