import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/logo/logo.svg';
import {useTranslation} from "react-i18next";

const FooterLogo = styled.img`
  max-width: 190px;
  height: auto;
  margin-bottom: 1.5rem;
`;
const CompanyDesc = styled.p`
  padding-top: 2.5rem;
  padding-right: 2rem;
  font-size: .8rem;
  line-height: 1.2rem;
  font-weight: 300;
  color: ${props => props.theme.colors.primary};
`;

const HomeFooterDesc = () => {
    const { t } = useTranslation();
    const shortDesc = t('footer.shortCompanyDesc');

    return (
        <>
            <FooterLogo src={logo} alt={`Agnieszka Łuczak`}/>
            <CompanyDesc>{ shortDesc }</CompanyDesc>
        </>
    );
};

export default HomeFooterDesc;
