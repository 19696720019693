import React from 'react';
import { Route } from "react-router-dom";

import HomeScreen from "../screens/Home.screen";
import ContactScreen from "../screens/Contact.screen";
import AboutScreen from "../screens/About.screen";
import ServiceScreen from "../screens/Service.screen";
import LoanComperator from "../screens/LoanComperator.screen";
import {useTranslation} from "react-i18next";

const Routes = () => {

    const { t } = useTranslation();
    const servicesSlug = t('sections.services.slug');

    return (
        <>
            <Route exact path="/" component={HomeScreen} />
            <Route exact path={`/${servicesSlug}/:slug`} component={ServiceScreen} />
            <Route exact path="/porownywarka-kredytow" component={LoanComperator} />
            <Route exact path="/o-mnie" component={AboutScreen} />
            <Route exact path="/kontakt" component={ContactScreen} />
        </>
    );
};

export default Routes;
