import React from 'react';
import AccentButton from "../button/AccentButton";

const HomeFooterContactCol = ({title, children}) => {
    return (
        <>
            <AccentButton className={`FooterAccentButton`} left>{ title }</AccentButton>
            { children }
        </>
    );
};

export default HomeFooterContactCol;