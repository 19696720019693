import React  from 'react';
import Anime from "react-anime";
import styled from "styled-components";

const IconsText = styled.h2`
  text-transform: uppercase;
  font-size: .85rem;
  color: ${props => props.theme.colors.secondary};
  text-align: center;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: .7rem;
  > span.word {
    display: flex;
    > span{
      display: inline-block;
      opacity: 0
    }
  }
`;

const IconText = ({title, animate, randomiseDelay}) => {

    const splitToWords = title.split(' ');
    const initAnimeProps = {
        translateY: -10,
        opacity: 1
    };
    const perLetterDelay = 40;
    const countWordDelay = (word, iword) => (iword * (word.length * perLetterDelay));
    const countLetterDelay = (i) => randomiseDelay + (i * perLetterDelay);

    return (
        <IconsText>
            {
                splitToWords.map((word, iword) => (
                    <span className={`word`} key={word+title}>
                        <Anime
                            key={Date.now() + 'icon_text_' + title + '_' + word}
                            delay={(e, i) => countWordDelay(word, iword) + countLetterDelay(i)}
                            autoplay={animate}
                            {...initAnimeProps}
                        >
                        {
                            word.split('').map((letter, i) => (
                                <span key={`${i}_${letter}_${title}`}>{letter}</span>
                            ))
                        }
                        </Anime>
                    </span>
                ))
            }
        </IconsText>
    );
};

export default IconText;