import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Icon from "./Icon";
import IconText from "./IconText";
import Visiblity from "react-visibility-sensor";
import getRandomInt from './../../utils/helpers/getRandomInt';

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const ServiceIcon = ({title, icon, onClick}) => {

    const min = 200;
    const max = 800;

    const randomDelay = useCallback(() => {
        return getRandomInt(min, max)
    }, [min, max]);

    const [isVisible, setIsVisible] = useState(false);
    const [active, setActive] = useState(true);
    const handleVisibilityChange = (isVisible) => {
        if (isVisible && active) {
            setIsVisible(isVisible);
            setActive(false);
        }
    };

    return (
        <Visiblity
            onChange={handleVisibilityChange}
            offset={{top:50}}
            active={active}
        >
            <IconContainer onClick={onClick}>
                <Icon icon={icon} animate={isVisible} randomiseDelay={randomDelay()} />
                <IconText title={title} animate={isVisible} randomiseDelay={randomDelay()}/>
            </IconContainer>
        </Visiblity>
    );
};

export default ServiceIcon;