import React, { useState, useEffect, createRef } from 'react';
import styled from "styled-components";
import media from "../../theme/mediaQuery";
import useWindowScrollPosition from '@rehooks/window-scroll-position';

const lineSize = `6rem`;
const LineContainer = styled.div`
    width: 2px;
    height: calc(100% - ${lineSize} - 30px);
    top: calc(${lineSize} / 2 + 5rem + 30px);
    left: 50%;
    transform: translateX(-1px);
    position: absolute;
    display: block;
    overflow: hidden;
    @media ${media.sm} {
      left: 30px;
    }
`;
const Line = styled.div`
  width: 100%;
  height: 100%;
  border-right: 2px solid ${props => props.theme.colors.white};
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: .2s all ease-in;
`;

const StepLine = ({offsetAnimeTop}) => {

    const [clientBoundingRect, setClientBoundingRect] = useState(null);
    const lineRefContainer = createRef();
    const lineRef = createRef();
    const { y } = useWindowScrollPosition({throttle: 100});
    const offsetY = offsetAnimeTop;
    useEffect(() => {
        setClientBoundingRect(lineRefContainer.current.getBoundingClientRect());
    }, []);

    useEffect(() => {
         //.offsetHeight
        if (clientBoundingRect) {
            const windowHeight = window.innerHeight;
            const {height: lineHeight, y: lineY} = clientBoundingRect;
            const bottomWindowEdge = windowHeight + y;

            const value = lineY - bottomWindowEdge + offsetY;
            const res = lineHeight + value;
            const translateY = res / lineHeight * -100;
            if (translateY >= -100 && translateY) {
                const transRound = translateY >= 0 ? 0 : translateY;
                lineRef.current.style.transform = `webkitTranslateY(${transRound}%)`;
                lineRef.current.style.transform = `mozTranslateY(${transRound}%)`;
                lineRef.current.style.transform = `msTranslateY(${transRound}%)`;
                lineRef.current.style.transform = `translateY(${transRound}%)`;
            }
        }
    }, [y]);


    return (
        <LineContainer
            ref={lineRefContainer}
        >
            <Line ref={lineRef} />
        </LineContainer>
    );
};

export default StepLine;
