import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import StepNumber from './StepNumber';
import StepLine from './StepLine';
import media from '../../theme/mediaQuery';
import Anime from "react-anime";
import Visiblity from "react-visibility-sensor";
import CooperationIcons from '../../assets/icons/cooperation_icons';

const StepContainer = styled(Row)`
  position: relative;
  flex-direction: ${props => props.right ? 'row-reverse' : 'row'};
`;

const SingleContainer = styled(Col)`
  opacity: ${props => props.icon ? 0 : 1};
  text-align: ${props => props.right ? 'left' : 'right'};  
  @media ${media.sm} {
    display: ${props => props.icon ? 'none' : 'block'};
    text-align: left;
  }
  position: relative;
  padding: 2rem;
  svg{
    fill: ${props => props.theme.colors.secondary};
    display: block;
    max-height: 250px;
    width: auto;
    margin: 5rem auto 0 auto;
  }
  h4{
    padding: 3rem 2rem 0 2rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
    opacity: 0;
  }
  p{
    color: ${props => props.theme.colors.white};
    font-style: italic;
    font-weight: 400;
    text-align: justify;
    font-size: .9rem;
    opacity: 0;
  }
  p{
    padding: 0 2rem;
  }
`;

const TextContainer = ({title, text, right, stepNumber, isVisible, AnimeProps}) => (

    <SingleContainer  key={stepNumber+'_left'} right={right}>
        <Anime
            key={Date.now() + 'single_container_text'}
            autoplay={isVisible}
            {...AnimeProps}
            delay={(e, i) => i * 300}
        >
            <h4>{title}</h4>
            <p>{text}</p>
        </Anime>
    </SingleContainer>
);

const IconContainer = ({icon, stepNumber, right, isVisible, AnimeProps}) => {

    const Icon = CooperationIcons[icon];

    return (
        <Anime
            key={Date.now() + 'single_container_text'}
            opacity={1}
            autoplay={isVisible}
            {...AnimeProps}
        >
            <SingleContainer
                right={right}
                key={stepNumber+'_left'}
                icon
            >
                <Icon />
            </SingleContainer>
        </Anime>
    );
};

const SingleWizardSection = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const [active, setActive] = useState(true);
    const handleVisibilityChange = (visible) => {
        if (visible && active) {
            setIsVisible(visible);
            setActive(false);
        }
    };

    const AnimeProps = {
        opacity: 1,
        duration: 1000,
        easing: `linear`
    };

    return (
        <Visiblity
            offset={{top: props.offsetAnimeTop}}
            onChange={handleVisibilityChange}
            active={active}
            partialVisibility={true}
            minTopValue={100}
        >
            <StepContainer right={props.right}>
                <StepNumber number={props.stepNumber} isVisible={isVisible} AnimeProps={AnimeProps} />
                { false === props.isLast && <StepLine offsetAnimeTop={props.offsetAnimeTop} /> }
                    <Col sm={12} md={6}>
                        <TextContainer {...props} isVisible={isVisible} AnimeProps={AnimeProps} />
                    </Col>
                    <Col sm={12} md={6}>
                        {
                            props.icon && (
                                <IconContainer {...props} isVisible={isVisible} AnimeProps={AnimeProps} />
                            )
                        }
                    </Col>
            </StepContainer>
        </Visiblity>
    );
};

export default SingleWizardSection;
