import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { PhoneComponent } from "./Phone.component";
import { SocialsComponent } from "./Socials.component";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const ContactInfoComponent = ({history}) => {

    const { t } = useTranslation();
    const callMe = t('contact.callMe');
    const prefix = t('contact.tel');
    const phoneNumber = t('contact.phoneNumber');
    const followMe = t('contact.followMe');
    const contact = t('contact.contact');

    return (
        <Row>
            <Col md={8}>
                <PhoneComponent
                    callMe={ callMe }
                    phoneNumber={`${prefix} ${phoneNumber}`}
                    btnClick={() => history.push('/kontakt')}
                    btnText={contact}
                />
            </Col>
            <Col md={4}>
                <SocialsComponent
                    followMe={followMe}
                />
            </Col>
        </Row>
    );
};

export default withRouter(ContactInfoComponent);