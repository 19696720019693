import React from 'react';
import styled from 'styled-components';
import HeaderLogo from './HeaderLogo';
import H1 from '../typography/H1';
import Text from '../typography/Text';
import HeaderLaveNumber from './HeaderLaveNumber';
import {useTranslation} from 'react-i18next';
import ScrollIndicator from '../../ScrollIndicator';
import LettersWave from '../anime/LettersWave/LettersWave';
import media from '../../theme/mediaQuery';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 460px;
  z-index: 100;
  margin-right: -100px;
  @media ${media.sm} {
    margin-right: 0;
  }
  @media ${media.md} {
    width: auto;
  }
`;

const HeaderText = styled(Text)`
  font-size: 1.2rem;
  margin-bottom: 3rem;
`;

const HeaderContent = () => {
    const { t } = useTranslation();
    const arrayOfServices = t('header.descriptionBold', { returnObjects: true  });
    return (
        <Container>
            <HeaderLogo />
            <H1>{ t('header.title') }</H1>
            <HeaderText italic>
                { t('header.description') }
                <LettersWave elements={arrayOfServices} />
            </HeaderText>
            <HeaderLaveNumber />
            <ScrollIndicator />
        </Container>
    );
};

export default HeaderContent;
