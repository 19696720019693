import React from 'react';
import Anime from "react-anime";

export default ({anime}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.1922mm" height="43.1922mm" viewBox="0 0 557 557" >
             <g id="Warstwa_x0020_1">
                 <metadata id="CorelCorpID_0Corel-Layer"/>
                 <Anime
                     key={Date.now() + '_money_credit_one'}
                     rotate={[
                         {value: -6, duration: 600, delay: 0 },
                         {value: 0, duration: 600, delay: 1200 },
                     ]}
                     translateY={[
                         {value: -50, duration: 600, delay: 0 },
                         {value: 0, duration: 600, delay: 1200 }
                     ]}
                     autoplay={anime}
                     duration={600}
                     direction={`easeOutExpo`}
                     loop={true}
                 >
                     <path className="fill"
                           d="M162 139l110 0c6,0 10,2 14,5l0 0c3,4 5,8 5,13l0 231c0,5 -2,10 -5,13l0 1c-4,3 -8,5 -13,5l-111 0c-5,0 -10,-2 -13,-5l0 0c-4,-4 -6,-8 -6,-14l0 -230c0,-6 2,-10 6,-14 3,-3 8,-5 13,-5z"/>
                     <path id="fill" className="fill_primary"
                           d="M272 152l-110 0c-2,0 -3,0 -4,1 -1,2 -2,3 -2,5l0 230c0,2 1,3 2,4l0 0c1,2 2,2 4,2l111 0c1,0 3,-1 4,-2l0 0c1,-1 1,-2 1,-4l0 -231c0,-1 0,-3 -1,-4l-1 0c-1,-1 -2,-1 -4,-1z"/>
                     <path className="fill"
                           d="M199 167l37 0 6 0 0 7c0,4 2,8 5,11l0 0c3,3 7,5 11,5l0 0 7 0 0 6 0 154 0 6 -7 0 0 0c-4,0 -8,2 -11,5 -3,3 -5,7 -5,11l0 7 -6 0 -37 0 -7 0 0 -7c0,-4 -2,-8 -5,-11 -3,-3 -7,-5 -11,-5l-7 0 0 -6 0 -154 0 -6 7 0c4,0 8,-2 11,-5 3,-3 5,-7 5,-11l0 -7 7 0zm31 13l-26 0c-1,6 -4,10 -8,14 -3,4 -8,7 -14,8l0 142c6,1 11,4 14,8 4,4 7,8 8,14l26 0c1,-6 4,-10 8,-14 4,-4 9,-7 14,-8l0 -142c-6,-1 -10,-4 -14,-8l0 0c-4,-4 -7,-8 -8,-14z"/>
                 </Anime>
                 <Anime
                     key={Date.now() + '_money_credit_second'}
                     rotate={[
                         {value: 4, duration: 600, delay: 0 },
                         {value: 0, duration: 600, delay: 1200 },
                     ]}
                     translateY={[
                         {value: -60, duration: 600, delay: 0 },
                         {value: -10, duration: 600, delay: 1200 }
                     ]}
                     autoplay={anime}
                     duration={600}
                     direction={`easeOutExpo`}
                     loop={true}
                 >
                     <path className="fill"
                           d="M294 119l102 42c5,2 9,6 11,10l0 0c1,5 2,10 0,14l-89 214c-2,5 -5,8 -10,10l0 0c-4,2 -9,2 -14,0l-102 -42c-5,-2 -9,-6 -10,-10l0 0c-2,-5 -2,-10 0,-15l88 -213c2,-5 5,-8 10,-10 4,-2 9,-2 14,0z"/>
                     <path id="fill_0" className="fill_primary"
                           d="M392 173l-103 -42c-1,-1 -3,-1 -4,0 -2,0 -3,1 -3,3l-89 213c0,2 0,3 0,5l0 0c1,1 2,2 4,3l102 42c1,1 3,1 4,0l0 0c2,-1 3,-2 3,-3l89 -213c0,-2 0,-4 0,-5l0 0c-1,-1 -2,-2 -3,-3z"/>
                     <path className="fill"
                           d="M317 159l35 14 6 3 -3 6c-2,4 -2,8 0,12l0 0c2,4 5,7 9,9l0 0 6 2 -3 6 -58 142 -3 6 -6 -2 0 0c-4,-2 -8,-2 -12,0 -4,1 -7,4 -9,8l-2 6 -6 -2 -34 -14 -6 -3 2 -6c2,-4 2,-8 0,-12 -2,-4 -5,-7 -9,-9l-6 -2 3 -6 58 -142 3 -6 6 2c4,2 8,2 12,0 4,-1 7,-4 9,-8l2 -6 6 2zm24 24l-23 -10c-4,5 -8,8 -13,10 -5,2 -11,3 -16,2l-54 131c4,3 8,8 10,13 2,5 3,10 2,16l24 10c3,-5 7,-8 12,-10 5,-2 11,-3 16,-2l54 -131c-4,-3 -8,-8 -10,-13l0 0c-2,-5 -3,-11 -2,-16z"/>
                 </Anime>
                 <path className="fill"
                       d="M170 247l217 0c8,0 14,3 19,8 5,4 8,11 8,19l0 139c0,7 -3,14 -8,19 -5,5 -11,8 -19,8l-217 0c-7,0 -14,-3 -19,-8 -5,-5 -8,-12 -8,-19l0 -139c0,-8 3,-15 8,-19 5,-5 12,-8 19,-8z"/>
                 <path id="fill" className="fill_primary"
                       d="M387 259l-217 0c-4,0 -7,2 -10,5 -2,2 -4,6 -4,10l0 139c0,4 2,7 4,10 3,2 6,4 10,4l217 0c4,0 7,-2 10,-4 3,-3 4,-6 4,-10l0 -139c0,-4 -1,-8 -4,-10 -3,-3 -6,-5 -10,-5z"/>
                 <g id="_2657170308144">
                    <path className="fill"
                         d="M341 320l60 0 0 13 -60 0c-8,0 -14,3 -19,8 -5,5 -8,11 -8,19 0,7 3,13 8,18l0 0c5,5 11,8 19,8l60 0 0 13 -60 0c-11,0 -21,-5 -28,-12l0 0c-7,-7 -12,-17 -12,-27 0,-11 5,-21 12,-28 7,-7 17,-12 28,-12z"/>
                    <path className="fill" d="M346 347c7,0 12,6 12,13 0,6 -5,12 -12,12 -7,0 -13,-6 -13,-12 0,-7 6,-13 13,-13z"/>
                 </g>
                <path className="fill"
                   d="M279 0c76,0 146,31 196,82 51,50 82,120 82,197 0,76 -31,146 -82,196 -50,51 -120,82 -196,82 -77,0 -147,-31 -197,-82 -51,-50 -82,-120 -82,-196 0,-77 31,-147 82,-197 50,-51 120,-82 197,-82zm182 96c-47,-46 -111,-75 -182,-75 -72,0 -136,29 -183,75 -46,47 -75,111 -75,183 0,71 29,135 75,182 47,47 111,75 183,75 71,0 135,-28 182,-75 47,-47 75,-111 75,-182 0,-72 -28,-136 -75,-183z"/>
             </g>
        </svg>
    )
}