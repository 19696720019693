import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LettersWaveElement from "./LettersWaveElement";

const Container = styled.span`
  display: block;
  width: 100%;
  & > span {
    display: inline-block;
    min-width: 5px;
  }
`;

const LettersWave = ({ elements }) => {

    const duration = 4000;
    const lettersAnimation = 800;
    const freezeDuration = duration - lettersAnimation * 2;
    const all = elements.length;
    const [current, setCurrent] = useState(0);
    const letterAnimDuration = ((duration - freezeDuration) / 2) / elements[current].length;
    const lettersAnimDuration = letterAnimDuration * elements[current].length;

    //const delay = stagger(100, {from: 'center'});

    useEffect(() => {
        const changeTimer = setTimeout(() => {
            const next = current + 1;
            const isLast = all % current === 1;
            const index = isLast ? 0 : next;
            setCurrent(index);
        }, duration);
        return () => clearTimeout(changeTimer);
    }, [current, all]);

    return (
        <Container>
            <LettersWaveElement
                current={current}
                sentence={elements[current]}
                lettersAnimDuration={lettersAnimDuration}
                letterAnimDuration={letterAnimDuration}
                freezeDuration={freezeDuration}
            />
        </Container>
    );
};

export default LettersWave;