import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';

const RoundIcon = styled.div`
  position: absolute;
  border-radius: 50%; 
  background: ${props => props.theme.colors.darkPrimary};
  overflow: hidden;
  transform: translateY(-10px);
  opacity: 0;
  > svg {
      width: 90%;
      height: auto;
      margin: 5%;
      path, circle, .fill {
        transform-origin: 50% 50%;
        fill: white;
        transition: .2s fill ease-in-out;
      }
      path:not(.off_hover_fill), 
      circle:not(.off_hover_fill), 
      .fill:not(.off_hover_fill) {
        transition: .2s fill ease-in-out;
      }
      .stroke{
        stroke: white;
      }
      .fill_primary{
        fill: ${props => props.theme.colors.darkPrimary};
      }
      .fill, .fill_primary{
        transition: .2s fill ease-in-out;
      }
  }
  &:hover > svg {
    .fill:not(.off_hover_fill){
      fill: ${props => props.theme.colors.secondary};
    }
    .stroke{
      stroke: ${props => props.theme.colors.secondary};
    }
  }
`;

const IconRoundContainer = forwardRef(({icon}, ref) => {

    const SvgIcon = icon;

    const [iconHover, setIconHover] = useState(false);
    const onIconOver = (hover = true) => {
        setIconHover(hover);
    };

    return (
        <RoundIcon
            ref={ref}
            onMouseOver={() => onIconOver()}
            onMouseLeave={() => onIconOver(false)}
        >
            <SvgIcon
                anime={iconHover}
            />
        </RoundIcon>
    );
});

export default IconRoundContainer;