import styled from 'styled-components';

const GradientBGContainer = styled.div`
  margin: 0;
  width: 100%;
  padding-bottom: 200px;
  background: linear-gradient(135deg, ${props => props.theme.colors.primary} 0%, ${props => props.theme.colors.darkPrimary} 100%); 
`;

export default GradientBGContainer;
