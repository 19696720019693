import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from "styled-components";
import { AboutMeText } from '../typography/AboutMeText';
import Text from '../typography/Text';
import ContactInfoComponent from "../contactInfo/ContactInfo.component";

const HelloText = styled(Text)`
  font-size: .9rem;
  font-style: normal;
  text-align: left;
  padding-bottom: .2rem;
  margin-bottom: 0;
`;

const Name = styled(Text)`
  font-weight: bold;
  text-align: left;
  font-size: 2rem;
  padding-top: .1rem;
  color: ${props => props.theme.colors.darkPrimary};
`;

export const AboutComponent = ({children}) => {
    return (
        <Row>
            <Col>
                <HelloText>Witaj nazywam się,</HelloText>
                <Name>Agnieszka Łuczak</Name>
                {
                    children.map((el, i) => <AboutMeText key={i}>{ el }</AboutMeText>)
                }
                <ContactInfoComponent/>
            </Col>
        </Row>
    );
};
