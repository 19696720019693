import React, { useRef, useEffect } from "react";
import Konva from "konva";
import { Shape } from "react-konva";

const Particle = ({ x, y, size, mousePos, fill, pos }) => {

    const triangle = useRef();
    const distance = 20;

    useEffect(() => {
        const triangleObj = triangle.current;
        const posArray = pos.split('-');
        const directionX = posArray.indexOf('right') !== -1;
        const directionY = posArray.indexOf('top') !== -1;
        const inDistance =
            Math.abs(mousePos.x - x) < distance &&
            Math.abs(mousePos.y - y) < distance;

        if (inDistance && triangleObj.tween) {
            triangleObj.tween.play();
        }

        if (!triangleObj.tween) {
            const xDir = directionX ? (x + distance) : (x - distance);
            const yDir = directionY ? (y - distance) : (y + distance);
            triangleObj.tween = new Konva.Tween({
                node: triangleObj,
                duration: 1,
                scaleX: 1,
                scaleY: 1,
                shadowOpacity: .1,
                x: xDir,
                y: yDir,
                easing: Konva.Easings.ElasticEaseOut
            });
            if (!triangleObj.tween.onFinish) {
                triangleObj.tween.onFinish = () => {
                    triangleObj.tween.reverse();
                };
            }
        }


    }, [pos, mousePos, x, y]);

    return (
        <Shape
            ref={triangle}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(size / 2, 0);
                context.lineTo(0, size);
                context.lineTo(size, size);
                context.closePath();
                // (!) Konva specific method, it is very important
                context.fillStrokeShape(shape);
            }}
            fill={fill}
            x={x}
            y={y}
            shadowColor={`black`}
            shadowBlur={3}
            shadowOffset={{ x: -distance+4, y: distance/2+4 }}
            shadowOpacity={0}
            cache={true}
        />
    );
};

export default Particle;
