import React from 'react';
import Anime from 'react-anime';
import styled from 'styled-components';
import BoldText from "../../typography/BoldText";

const BoldTextStyled = styled(BoldText)`
  opacity: 0!important;
  color: ${props => props.theme.colors.darkPrimary};
`;

const LettersWaveElement = ({ current, sentence, letterAnimDuration, lettersAnimDuration, freezeDuration }) => {

    const arrayOfSentence = sentence.split('');

    const animeProps = {
        opacity: [
            {value: 0, duration: 0},
            {value: 1, delay: (el, i) => i * letterAnimDuration, duration: lettersAnimDuration},
            {value: 1, duration: freezeDuration},
            {value: 0, duration: lettersAnimDuration},
        ],
        translateY: [
            {value: 5, delay: (el, i) => i * letterAnimDuration, duration: lettersAnimDuration},
            {value: 5, duration: freezeDuration},
            {value: 0, duration: lettersAnimDuration},
        ]
    };

    return (
        <Anime key={Date.now() + current} {...animeProps} >
            { arrayOfSentence.map( (letter, index) => <BoldTextStyled key={index} dark >{ letter }</BoldTextStyled> )}
        </Anime>
    );
};

export default LettersWaveElement;