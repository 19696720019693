import React, { createRef } from 'react';
import styled from 'styled-components';
import Anime from "react-anime";
import IconRoundContainer from "./IconRoundContainer";

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 12px;
  position: relative;
`;

const Icon = ({icon, animate, randomiseDelay}) => {
    const ref = createRef;

    return (
        <IconContainer>
            <Anime
                children={ref.current}
                key={Date.now() + 'icon'}
                delay={randomiseDelay}
                duration={1000}
                opacity={1}
                translateY={`0`}
                autoplay={animate}
                easing={`linear`}
                direction={`normal`}
            >
                <IconRoundContainer icon={icon} ref={ref} />
            </Anime>
        </IconContainer>
    );
};

export default Icon;