import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import styled from 'styled-components';
import FooterSocialContainer from "./FooterSocialContainer";

const SmallFooterContainer = styled.footer`
  background-color: ${props => props.theme.colors.lightPrimary};
  p{
    color: ${props => props.theme.colors.primary};
    margin-bottom: 0;
    font-size: .7rem;
    padding: 1.36rem 0;
  }
`;

const Footer = () => {
    return (
        <SmallFooterContainer>
            <Container>
                <Row>
                    <Col>
                        <p>Copyrights 2019 © Agnieszka Łuczak</p>
                    </Col>
                    <Col>
                        <FooterSocialContainer />
                    </Col>
                </Row>
            </Container>
        </SmallFooterContainer>
    );
};

export default Footer;