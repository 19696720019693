import React from 'react';
import Anime from "react-anime";

export default ({ anime }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.1922mm" height="43.1922mm" viewBox="0 0 1232 1232" >
            <g id="Warstwa_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer"/>
            <path className="fill" d="M616 0c170,0 324,69 436,180 111,112 180,266 180,436 0,170 -69,324 -180,436 -112,111 -266,180 -436,180 -170,0 -324,-69 -436,-180 -111,-112 -180,-266 -180,-436 0,-170 69,-324 180,-436 112,-111 266,-180 436,-180zm404 213c-104,-103 -246,-167 -404,-167 -157,0 -300,64 -403,167 -103,103 -167,246 -167,403 0,158 64,300 167,404 103,103 246,167 403,167 158,0 300,-64 404,-167 103,-104 167,-246 167,-404 0,-157 -64,-300 -167,-403z"/>
            <Anime
                key={Date.now() + '_saving_rotate'}
                rotate={180}
                autoplay={anime}
                easing={`linear`}
                duration={1200}
                loop={true}
                transformOrigin={`50% 50%`}
            >
                <path className="fill" d="M632 165l75 73 99 -33c7,-3 15,1 18,8l0 0 35 99 104 12c8,1 13,8 12,16l0 1 -10 103 88 57c6,4 8,13 4,19l0 0 -54 89 54 89c4,7 2,16 -4,20l0 0 -88 56 10 104c1,8 -4,15 -12,16l0 0 -104 13 -35 98c-3,8 -11,11 -19,9l0 0 -98 -34 -75 73c-6,6 -15,6 -20,0l-75 -73 -99 34c-7,2 -15,-2 -18,-9l0 0 -35 -98 -104 -13c-8,-1 -14,-8 -13,-16l0 -1 11 -103 -88 -56c-7,-4 -9,-13 -4,-20l0 0 54 -89 -54 -89c-4,-7 -2,-15 4,-19l0 0 88 -57 -11 -104c0,-8 5,-15 13,-16l0 0 104 -12 35 -99c3,-7 11,-11 18,-8l0 0 99 33 75 -73c6,-5 14,-5 20,0zm61 100l-71 -70 -71 70 0 0c-4,3 -9,5 -15,3l-94 -32 -33 93c-2,5 -6,9 -12,10l-99 12 10 98c1,6 -1,11 -6,14l-84 54 52 85c3,4 3,10 0,15l-52 84 84 54 0 0c4,3 7,8 6,14l-10 98 99 13 0 0c5,0 10,4 11,9l34 93 94 -31c5,-2 11,-1 15,3l71 69 71 -69 0 0c4,-4 9,-5 14,-3l95 31 33 -92c2,-6 6,-10 12,-10l99 -13 -10 -98c-1,-5 1,-11 6,-14l84 -54 -52 -84c-3,-5 -3,-10 0,-15l52 -85 -84 -54 0 0c-4,-3 -7,-8 -6,-13l10 -99 -99 -12 0 0c-5,-1 -10,-4 -12,-10l-33 -93 -94 32c-5,2 -11,0 -15,-3z"/>
            </Anime>
            <path className="fill" d="M622 353c71,0 135,29 181,75 46,47 75,111 75,181 0,71 -29,135 -75,181 -46,46 -110,75 -181,75 -71,0 -135,-29 -181,-75 -46,-46 -75,-110 -75,-181 0,-70 29,-134 75,-181 46,-46 110,-75 181,-75zm161 95c-41,-41 -98,-66 -161,-66 -63,0 -120,25 -161,66 -41,42 -66,98 -66,161 0,63 25,120 66,161 41,41 98,67 161,67 63,0 120,-26 161,-67 41,-41 66,-98 66,-161 0,-63 -25,-119 -66,-161z"/>
                <Anime
                    key={Date.now() + '_saving_rotate_dolar'}
                    scaleX={[
                        {value: -1, duration: 600, delay: 0},
                        {value: 1, duration: 600, delay: 0}
                    ]}
                    autoplay={anime}
                    easing={`linear`}
                    direction={'alternate'}
                    transformOrigin={`50% 50%`}
                    loop={true}
                    duration={1200}
                >
                    <path className="fill" d="M637 725l0 39c0,8 -7,14 -14,14 -8,0 -15,-6 -15,-14l0 -39 -49 0c-8,0 -14,-6 -14,-14 0,-8 6,-14 14,-14l85 0c10,0 19,-4 25,-11 6,-6 10,-15 10,-25l0 0c0,-9 -4,-18 -10,-25 -6,-6 -15,-10 -25,-10l-13 0 -13 0 -18 0c-18,0 -34,-7 -45,-19 -12,-12 -19,-28 -19,-45l0 0c0,-18 7,-34 19,-46 11,-11 27,-18 45,-18l8 0 0 -44c0,-7 7,-14 15,-14 7,0 14,7 14,14l0 44 48 0c7,0 14,6 14,14 0,8 -7,14 -14,14l-85 0c-10,0 -19,4 -25,11 -7,6 -11,15 -11,25l0 0c0,9 4,18 11,25 6,6 15,10 25,10l18 0 13 0 13 0c18,0 34,7 45,19 12,12 19,28 19,45l0 0c0,18 -7,34 -19,46 -11,11 -27,18 -45,18l-7 0z"/>
                </Anime>
            </g>
        </svg>
    );
}
