import React from 'react';
import Anime from "react-anime";
import {withTheme} from "styled-components";
import getRandomInt from './../../../utils/helpers/getRandomInt';

const CompanyCredit = ({ anime, theme }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="43.1922mm" height="43.1922mm" viewBox="0 0 952 952">
             <path className="fill circle"
                   d="M476 0c131,0 250,53 336,139 87,87 140,206 140,337 0,131 -53,250 -140,336 -86,87 -205,140 -336,140 -131,0 -250,-53 -337,-140 -86,-86 -139,-205 -139,-336 0,-131 53,-250 139,-337 87,-86 206,-139 337,-139zm312 164c-80,-79 -190,-129 -312,-129 -122,0 -232,50 -312,129 -79,80 -129,190 -129,312 0,122 50,232 129,312 80,79 190,129 312,129 122,0 232,-50 312,-129 79,-80 129,-190 129,-312 0,-122 -50,-232 -129,-312z"/>
             <polygon className="fill" points="117,751 825,751 825,773 117,773 "/>
             <path className="fill"
                   d="M249 388l189 0 11 0 0 11 0 363 0 11 -11 0 -189 0 -11 0 0 -11 0 -363 0 -11 11 0zm178 22l-167 0 0 341 167 0 0 -341z"/>
             <path className="fill"
                   d="M438 170l155 0 11 0 0 11 0 53 0 11 -11 0 -155 0 -11 0 0 -11 0 -53 0 -11 11 0zm144 22l-133 0 0 31 133 0 0 -31z"/>
             <path className="fill"
                   d="M438 223l261 0 11 0 0 11 0 528 0 11 -11 0 -261 0 -11 0 0 -11 0 -528 0 -11 11 0zm250 22l-239 0 0 506 239 0 0 -506z"/>
             <path className="fill"
                   d="M284 337l154 0 11 0 0 11 0 51 0 11 -11 0 -154 0 -11 0 0 -11 0 -51 0 -11 11 0zm143 22l-132 0 0 29 132 0 0 -29z"/>
               <Anime
                   key={Date.now() + '_company_credit_komin'}
                   autoplay={anime}
                   fill={[
                       { value: theme.colors.darkPrimary, duration: 1, delay: 0},
                       { value: theme.colors.darkPrimary, duration: 1, delay: 800},
                       { value: theme.colors.secondary, duration: 1, delay: (e, i) => getRandomInt(100, 2000) },
                   ]}
                   loop={false}
                   direction={'normal'}
               >
                 <polygon className="fill off_hover_fill" points="288,460 400,460 400,486 288,486 "/>
                 <polygon className="fill off_hover_fill" points="483,320 656,320 656,346 483,346 "/>
                 <polygon className="fill off_hover_fill" points="483,404 656,404 656,430 483,430 "/>
                 <polygon className="fill off_hover_fill" points="483,488 656,488 656,514 483,514 "/>
                 <polygon className="fill off_hover_fill" points="288,530 400,530 400,556 288,556 "/>
                 <polygon className="fill off_hover_fill" points="288,602 400,602 400,628 288,628 "/>
                 <polygon className="fill off_hover_fill" points="288,678 400,678 400,704 288,704 "/>
                </Anime>
             <path className="fill"
                   d="M522 653l88 0 11 0 0 11 0 98 0 11 -11 0 -88 0 -11 0 0 -11 0 -98 0 -11 11 0zm77 22l-66 0 0 76 66 0 0 -76z"/>
      </svg>
  );
};

export default withTheme(CompanyCredit);
