import React from 'react';
import { useTranslation } from "react-i18next";
import Helmet from "../components/helmet/helmet";
import EmbeddedWidgetComponent, { WidgetsNames } from '../components/lendi/EmbeddedWidget.component';
import withGACurrentPageview from "../hoc/withGACurrentPageview";

const LoanComperator = () => {

    const { t } = useTranslation();
    const helmet = t('loanComperator.helmet', { returnObjects: true });

    return (
        <div style={{minHeight: '100vh', textAlign: 'center'}}>
            <Helmet helmet={helmet} />
            <EmbeddedWidgetComponent 
                widgetName={WidgetsNames.LIGHT_COMPARER_WIDGET}
                widgetProps={{ height: 2500 }}
            />
        </div>
    );
};

export default withGACurrentPageview(LoanComperator);
