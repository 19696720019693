import React from 'react';
import { Image } from "react-bootstrap";
import styled from "styled-components";
import woman from '../../assets/images/header/woman.png';
import media from '../../theme/mediaQuery';

const WomanImage = styled(Image)`
  position: relative;
  height: 80%;
  width: auto;
  bottom: 43px;
  margin-right: -150px;
  @media ${media.sm} {
    display: none;
  }
`;

const Woman = () => {
    return (
        <WomanImage src={woman} />
    );
};

export default Woman;
