import React from 'react';
import Routes from './Routes';

import GlobalContainer from '../components/content/GlobalContent';
import NavContainer from "../components/nav/NavContainer";
import Footer from "../components/footer/Footer";

const AppContainer = () => {

    return (
        <GlobalContainer fluid={true}>
            <NavContainer />
            <Routes />
            <Footer />
        </GlobalContainer>
    );
};

export default AppContainer;
