import React from 'react';
import { Transition } from "react-transition-group"
import {Image} from "react-bootstrap";
import logo from '../../assets/images/logo/logo.svg';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  display: ${({ state }) => (state === "exited" ? 'none' : 'flex')};
  height: 100%; 
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: ${props => props.theme.colors.primaryBg};
  align-items: center;
  justify-content: center;
  opacity: ${({ state }) => (state === "exiting" || state === "exited" ? 0 : 1)};
  transition: opacity .5s ease-in-out .3s;
  z-index: 99999;
`;

const AppLoader = ({disappear}) => {

    return (
        <Transition in={!disappear} timeout={800} unmountOnExit>
            {(state) => {
                /*state change: exited -> entering -> entered -> exiting -> exited*/
                return(
                    <Overlay className={`AppLoader`} state={state}>
                        <Image src={logo} alt={`Agnieszka Łuczak`}/>
                    </Overlay>
                );
            }}
        </Transition>
    );
};

export default AppLoader;
