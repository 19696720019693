import React, { useState, useEffect } from 'react';
import { InputGroup } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import LeaveNumberBtn from './LeaveNumberBtn';
import {faCheck, faSave, faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {sendContactRequest} from "../../api/actions/sendContactRequest";

const Container = styled(InputGroup)`
  width: ${ props => props.status === statuses.SAVED ? `300px` : `220px` };
  margin-left: 21px;
`;
const Prepend = styled(InputGroup.Prepend)`
  & > span {
    padding-left: 20px;
  }
`;
const Text = styled(InputGroup.Text)`
  border: none;
  border-radius: 0;
  background: ${props => props.theme.colors.secondary};
  font-size: .7rem;
  padding-right: 3px;
`;
const Input = styled(InputMask)`
  height: 43px;
  border-radius: 0;
  border: none;
  background: ${props => props.theme.colors.secondary};
  font-weight: 900;
  font-size: 1.2rem;
  padding-left: 3px;
  &::placeholder{
    color: ${props => props.theme.colors.darkSecondary};
  }
  &:focus {
    background: ${props => props.theme.colors.secondary};  
    outline: none;
    box-shadow: none;
  }
`;
const ThanksBar = styled.span`
  height: 43px;
  padding: .58rem .75rem;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
  font-weight: 700;
  border: none;
  border-radius: 0;
  text-align: center;
`;

const ErrorBar = styled(ThanksBar)`
  height: 43px;
  padding: .58rem .75rem;
  background: transparent;
  color: ${props => props.theme.colors.error};
  font-weight: 700;
  border: none;
  text-align: center;
`;

export const statuses = {
    NOT_SAVED: 'not_saved',
    SAVED: 'saved',
    FETCHING: 'fetching',
    ERROR: 'error'
};

const LeaveNumberInput = ({setIsSelected, setIsUnselected}) => {

    const { t } = useTranslation();

    const initialValue = '';
    const [value, setValue] = useState(initialValue);
    const [showBtn, setShowBtn] = useState(false);
    const [status, setStatus] = useState(statuses.NOT_SAVED);
    const [thanksText, setThanksText] = useState(null);
    const [errorText, setErrorText] = useState(null);

    const onInputChange = (e) => {
        setValue(e.target.value.toString().split(' ').join(''));
        clearTexts();
        setStatus(statuses.NOT_SAVED);
    };
    const clearTexts = () => {
        setThanksText(null);
        setErrorText(null);
    };
    const handleStatusUnsave = () =>  setStatus(statuses.NOT_SAVED);
    const handleStatusSave = () => {
        //simulate axios
        setStatus(statuses.FETCHING);
        sendContactRequest(value)
            .then(response => {
                setThanksText("Dziękuję! Oczekuj na telefon!");
                setStatus(statuses.SAVED);
            })
            .catch(error => {
                setStatus(statuses.ERROR);
                setErrorText("Niepoprawny numer telefonu!");
            });
    };
    const [icon, setIcon] = useState(faSave);
    const btnAction = status === statuses.SAVED ? handleStatusUnsave : handleStatusSave;

    useEffect(() => {
        switch(status) {
            case statuses.SAVED:
                setShowBtn(true);
                setIcon(faCheck);
                setIsSelected();
                break;
            case statuses.FETCHING:
                setIcon(faCircleNotch);
                setShowBtn(true);
                break;
            case statuses.ERROR:
                setShowBtn(false);
                break;
            case statuses.NOT_SAVED:
            default:
                setIcon(faSave);
                setIsUnselected();
                setShowBtn(false);
                setValue(initialValue);
        }
    }, [status, setIsSelected, setIsUnselected]);
    useEffect(() => {
        if (statuses.NOT_SAVED) {
            if (value.length >= 9) {
                setShowBtn(true);
            } else {
                setShowBtn(false)
            }
        }
    }, [value]);

    return (
        <Container status={status}>
            {
                status !== statuses.SAVED ? (
                    <>
                        <Prepend>
                            <Text id="prefix">+48</Text>
                        </Prepend>
                        <Input
                            placeholder="123 456 789"
                            aria-label={t('header.ariaLabelPhoneInput')}
                            aria-describedby="prefix"
                            mask="999 999 999"
                            maskChar=" "
                            className={`form-control`}
                            value={value}
                            onChange={onInputChange}
                        />
                        {
                            errorText && (
                                <div>
                                    <ErrorBar className={'form-control'}>
                                        {errorText}
                                    </ErrorBar>
                                </div>
                            )
                        }
                    </>
                ) : (
                    thanksText && (
                        <ThanksBar className={'form-control'}>
                            {thanksText}
                        </ThanksBar>
                    )
                )
            }
            {
                showBtn && (
                    <LeaveNumberBtn
                        status={status}
                        onClick={btnAction}
                        icon={icon}
                    />
                )
            }
        </Container>
    );
};

export default LeaveNumberInput;
