import React from 'react';
import styled from 'styled-components';

const Heading1 = styled.h1`
  color: ${props => props.color ? props.color : props.theme.colors.black };
  font-size: 1.8rem;
  margin: 5vh 0 3vh 0;
  text-align: center;
  font-weight: 600;
`;

const H1 = ({ children }) => {
    return (
        <Heading1>{ children }</Heading1>
    );
};

export default H1;