import React from 'react';
import {withTheme} from "styled-components";

const OfferChoose = ({ anime, theme }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="84.6617mm" height="68.344mm" viewBox="0 0 2384 1924">
            <g id="Warstwa_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer"/>
                <g id="list">
                    <path className="fil0"
                        d="M412 294c39,4 73,24 96,52 23,29 35,66 31,105 -4,39 -23,73 -52,96 -28,23 -65,36 -104,32 -40,-4 -73,-24 -97,-52 -23,-29 -35,-66 -31,-105 4,-39 24,-73 52,-96 28,-23 66,-36 105,-32zm52 88c-14,-17 -34,-29 -58,-32 -24,-2 -46,5 -63,19 -18,14 -29,35 -32,59 -2,23 5,46 19,63 14,17 35,29 58,32 24,2 47,-5 64,-19 17,-14 29,-35 31,-59 3,-24 -5,-46 -19,-63z"/>
                    <path className="fil0"
                        d="M382 586c39,4 73,24 96,52 23,28 35,66 31,105 -4,39 -23,73 -51,96 -29,23 -66,35 -105,31 -39,-4 -73,-23 -96,-52 -24,-28 -36,-65 -32,-104 4,-40 24,-73 52,-97 28,-23 66,-35 105,-31zm52 88c-14,-18 -34,-29 -58,-32 -24,-2 -46,5 -63,19 -18,14 -29,35 -32,58 -2,24 5,47 19,64 14,17 35,29 58,31 24,3 47,-5 64,-19 17,-14 29,-34 31,-58 3,-24 -5,-46 -19,-63z"/>
                    <path className="fil0"
                       d="M442 1c38,4 71,22 94,49l-48 31c-13,-13 -31,-22 -52,-24 -24,-3 -46,5 -63,19 -18,14 -29,34 -32,58 -2,24 5,46 19,64 14,17 35,29 58,31 24,2 47,-5 64,-19 6,-5 11,-10 16,-17l72 -46c0,3 0,7 -1,10 -4,40 -23,74 -51,97 -29,23 -66,35 -105,31 -40,-4 -73,-24 -96,-52 -24,-28 -36,-65 -32,-105 4,-39 24,-73 52,-96 28,-23 66,-35 105,-31z"/>
                    <path className="fil0"
                       d="M355 127c-7,-9 -5,-23 5,-30 10,-7 23,-4 30,5l37 52 157 -101c10,-7 24,-4 30,6 7,10 4,23 -6,30l-175 112c-9,6 -22,4 -29,-5l-49 -69z"/>
                    <path className="fil0"
                       d="M657 189c-16,-2 -27,-16 -25,-31 1,-16 15,-27 31,-25l403 41c15,2 26,15 25,31 -2,15 -16,27 -31,25l-403 -41z"/>
                    <path className="fil0"
                       d="M626 488c-15,-1 -27,-15 -25,-31 2,-15 15,-26 31,-25l403 41c16,2 27,16 25,31 -1,16 -15,27 -31,25l-403 -41z"/>
                    <path className="fil0"
                       d="M596 780c-15,-2 -26,-15 -25,-31 2,-15 16,-27 31,-25l403 41c16,2 27,16 25,31 -1,16 -15,27 -31,25l-403 -41z"/>
                </g>
                <g id="quarter">
                    <path className="fil0"
                        d="M1853 752c120,-23 239,4 334,68 94,64 165,163 188,284l0 0c24,121 -4,239 -67,334 -64,94 -164,165 -284,188 -121,24 -240,-4 -334,-67 -95,-64 -165,-163 -189,-284 -3,-15 7,-30 23,-33l381 -75 -74 -382c-3,-15 6,-30 22,-33l0 0zm302 115c-75,-51 -168,-76 -264,-64l75 381c3,15 -7,30 -22,33l-381 75c25,93 83,170 158,220 83,56 187,80 292,59 106,-20 193,-82 248,-164 55,-83 80,-186 59,-291 0,-1 0,-2 0,-3 -21,-104 -82,-191 -165,-246z"/>
                    <path className="fil0"
                        d="M1420 1166c-20,-99 3,-198 55,-276 53,-78 136,-136 235,-156 16,-3 30,7 33,22l66 334c3,15 -7,30 -22,33l-334 66c-16,3 -30,-7 -33,-23zm102 -245c-40,59 -59,132 -51,207l277 -55 -54 -277c-73,21 -132,66 -172,125z"/>
                </g>
                <g id="paper">
                    <path className="fil0"
                        d="M180 1524c-15,2 -29,-9 -31,-25 -1,-15 10,-29 25,-31l551 -61c16,-1 30,10 31,25 2,15 -9,29 -24,31l-552 61z"/>
                    <path className="fil0"
                        d="M193 1645c-16,1 -30,-10 -31,-25 -2,-16 9,-30 24,-31l552 -61c15,-2 29,9 31,24 1,16 -10,30 -25,32l-551 61z"/>
                    <path className="fil0"
                        d="M209 1759c-15,2 -29,-9 -31,-25 -2,-15 9,-29 25,-31l263 -29c16,-1 30,10 31,25 2,16 -9,29 -24,31l-264 29z"/>
                    <path className="fil0"
                        d="M186 1860c16,-2 30,9 31,25 2,15 -9,29 -25,31l-74 8 -1 0 0 0c-20,2 -37,-5 -50,-17 -12,-12 -20,-28 -22,-47l0 -1 0 0 -45 -451c-2,-20 6,-39 18,-53 12,-15 30,-25 49,-28l739 -97 1 0 0 0 1 0c1,0 1,0 2,0 19,-2 35,5 48,17 12,11 19,27 22,45 0,1 0,2 0,2l0 1 47 468c2,20 -5,39 -17,53 -13,15 -31,25 -50,28l-383 41c-16,2 -30,-10 -31,-25 -2,-15 9,-29 25,-31l383 -41c5,-1 9,-4 13,-8 3,-4 5,-8 5,-12l-48 -467 0 -1 0 -1c-1,-5 -2,-9 -5,-12 -1,-1 -3,-1 -5,-1l0 0 -1 0 0 0 -739 97c-5,1 -10,4 -13,8 -3,4 -5,8 -5,12l45 450 0 0 0 1c1,6 2,10 5,12 1,1 3,2 6,2l0 0 74 -8z"/>
                </g>
            </g>
        </svg>
    );
};

export default withTheme(OfferChoose);
