import styled from 'styled-components';
import Text from './Text';

export const LightSpaceText = styled(Text)`
  color: ${ props => props.theme.colors.lightPrimary };
  padding: 0;
  margin: 0;
  text-align: ${props => props.right ? 'right' : 'left'};
  font-size: .75rem;
  letter-spacing: .3rem;
  opacity: .5;
`;