import React, { useRef, useEffect, useState } from 'react';
import styled, {withTheme} from 'styled-components';
import Accents from "./Accents";
import use3DtransformBoxHook from "../../hooks/use3DtransformBoxHook";

const AccentBox = styled.div`
    position: relative;
    z-index: 0;
    transition: all .3s ease-in-out;
    perspective: 30px;
    margin-top: 5rem;
  & > div.content{
      background-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.darkPrimary};
      padding: 5rem 3.4rem;
      z-index: 100;
      position: relative;
      transition: all .1s linear; 
      box-shadow: 0 0 10px rgba(0, 0, 0, .5);
      border-left: .00001rem solid ${props => props.theme.colors.secondary};
  }
  &:hover {
    > div.content{
      box-shadow: -10px -4px 8px rgba(0, 0, 0, .2);
      border-left: .7rem solid ${props => props.theme.colors.lightPrimary};
    }
  }
  & > div.content > { 
      h4 {
        font-weight: 800;
        font-size: 1.2rem;
        margin-bottom: 2rem;
        > small {
          width: 100%;
          text-transform: capitalize;
          display: inline-block;
        }
      }
      p {
        margin-bottom: 1rem;
        line-height: 1rem;
        font-size: .9rem;
        text-align: justify-all;
      }
  }
`;

const AccentTextBox = ({ children, title, subtitle, theme }) => {

    const ElementRef = useRef(null);
    const [element, setElement] = useState(null);

    useEffect(() => {
        setElement(ElementRef.current);
    }, []);

    const {ref, onMouseMove, onMouseLeave} = use3DtransformBoxHook();

    return (
        <AccentBox
            ref={ElementRef}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
        >
            <Accents
                parent={element}
                position={['top-left', 'bottom-right']}
                color={theme.colors.darkSecondary}
                heightScale={.4}
            />
            <div
                className="content"
                ref={ref}
            >
                <h4>
                    <small>{ subtitle }</small>
                    { title }
                </h4>
                { children }
            </div>
        </AccentBox>
    );
};

export default withTheme(AccentTextBox);