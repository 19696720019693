import React from 'react';
import styled from 'styled-components';
import Particles from "../particles/particles";

const Container = styled.div(props => {

    const pos = {};
    const posArray = props.pos.split('-');
    const margins = props.margins;

    if (posArray.indexOf('left') > -1) {
        pos.left = `${margins}px`;
    }
    if (posArray.indexOf('top') > -1) {
        pos.top = `${margins}px`;
    }
    if (posArray.indexOf('right') > -1) {
        pos.right = `${margins}px`;
    }
    if (posArray.indexOf('bottom') > -1) {
        pos.bottom = `${margins}px`;
    }

    return {
        position: 'absolute',
        'z-index': 0,
        ...pos
    };
});

const Accents = ({parent, position, color, heightScale = 1, widthScale = 1, marginSize = -90}) => {

    return (
        parent && (
            position.map(pos => (
                <Container pos={pos} key={pos} margins={marginSize}>
                    {
                        <Particles
                            width={parent.offsetWidth * widthScale}
                            height={parent.offsetHeight * heightScale}
                            color={color}
                            pos={pos}
                        />
                    }
                </Container>
            ))
        )
    );
};

export default Accents;