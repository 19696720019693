import React from 'react';
import styled from 'styled-components';

const HR = styled.hr`
  border-bottom: 3px solid ${props => props.theme.colors.secondary};
  margin-bottom: 3rem;
`;

export const LineSeparator = ({property}) => {
    return (
        <HR/>
    );
};
