import React from 'react';
import styled from "styled-components";
import marker from "../../assets/images/contact/marker.png";

const MarkerDiv = styled.div`
  width: 29px;
  height: 43px;
  display: block;
  transform: translate(-14.5px, -43px);
  background-image: url(${marker});
`;

const Marker = (props) => {
    return (
        <MarkerDiv {...props} />
    );
};

export default Marker;
