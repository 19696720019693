import React from 'react';
import styled from 'styled-components';
import headerBg from '../../assets/images/header/header_bg.jpg';
import Woman from './Woman';
import HeaderContent from './HeaderContent';
import media from '../../theme/mediaQuery';

const HeaderContainer = styled.section`
  width: 100%;
  height: calc(90vh - ${props => props.theme.sizes.nav.height});
  background-image: url(${headerBg});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  position:relative;
  clip-path: polygon(-5% 0%, 100% 0, 100% 80%, 30% 100%);
  @media ${media.md} {
    clip-path: polygon(-40% 0%, 100% 0, 100% 90%, 10% 100%);
  }
`;

const Header = () => {
    return (
        <HeaderContainer className={`Header`}>
            <HeaderContent/>
            <Woman/>
        </HeaderContainer>
    );
};

export default Header;
