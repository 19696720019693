import React from 'react';
import Anime from "react-anime";

export default ({ anime }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.1922mm" height="43.1922mm"  viewBox="0 0 360 360" >
            <g id="Warstwa_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer"/>
                <path className="fill"
                   d="M183 133c23,0 43,7 57,17 15,11 25,25 27,41l12 2c4,0 7,2 10,4 2,3 4,6 3,10l-3 25c0,4 -2,7 -4,10 -3,2 -6,3 -9,3l-35 0c0,1 -1,2 -2,2 0,0 0,0 -1,1l10 18c1,3 1,6 0,9 -1,3 -3,5 -6,6l-16 8 0 0 0 0c-2,2 -5,2 -8,1 -3,-1 -5,-3 -7,-5l0 0 0 0 -11 -22c0,-1 0,-1 0,-1 -2,0 -4,1 -6,1 -3,0 -7,0 -10,0 -3,0 -5,0 -8,0 -2,0 -3,0 -5,-1 0,1 0,1 0,2l-13 21c-1,2 -4,4 -7,4 -3,1 -6,0 -9,-1l-15 -9 0 0 0 0 0 0c-3,-2 -4,-4 -5,-7 -1,-2 0,-5 1,-8l0 0 0 0 9 -15c-10,-6 -18,-13 -24,-22 -6,-9 -10,-19 -10,-30 0,-18 10,-34 26,-46 15,-11 36,-18 59,-18zm53 23c-14,-10 -32,-16 -53,-16 -21,0 -41,7 -55,17 -14,10 -22,24 -22,40 0,9 3,18 8,26 6,8 15,16 25,22l3 1 -2 4 -11 18 0 0 0 0c0,1 0,2 0,2 0,1 1,2 2,3l0 0 0 0 15 9c1,0 2,0 4,0 1,0 2,0 2,-1l13 -21c0,0 0,-1 0,-1 0,0 0,-1 0,-1l0 -4 4 1c3,0 5,0 8,1 2,0 4,0 7,0 3,0 6,0 9,-1 3,0 6,0 9,-1l4 -1 0 5c0,0 0,0 1,1 0,0 0,0 0,0l11 22 0 0 0 0c0,1 1,2 2,2 1,0 2,0 3,0l0 0 0 0 16 -8c1,-1 2,-2 2,-3 0,-1 1,-2 0,-3l-11 -21 -1 -3 3 -2c1,0 2,-1 3,-2 1,-1 2,-1 3,-2l1 -1 1 0 36 0c1,0 3,-1 4,-2 1,-1 2,-3 2,-5l3 -25c0,-1 0,-3 -1,-4 -2,-1 -4,-2 -6,-2l-15 -2 -3 0 0 -3c-1,-15 -10,-29 -24,-39z"/>
                <Anime
                    key={Date.now() + '_saving_monay_two'}
                    translateY={[
                        {value: 20, delay:0, duration: 800},
                        {value: 0, delay:0, duration: 600},
                        {value: 0, delay:1000, duration: 800},
                    ]}
                    autoplay={anime}
                    direction={'alternate'}
                    easing={`easeInOutQuart`}
                    loop={true}
                >
                    <path className="fill"
                       d="M176 46c7,5 11,12 12,19 1,7 -1,15 -6,21 -4,7 -11,11 -19,12 -7,1 -15,-1 -21,-6 -7,-4 -10,-11 -12,-19 -1,-7 1,-15 6,-21 5,-7 12,-10 19,-12 7,-1 15,1 21,6z"/>
                    <path id="fill" className="fill_primary"
                       d="M181 66c-1,-5 -4,-11 -9,-14 -5,-4 -11,-5 -16,-4 -6,1 -11,3 -14,8 -4,5 -5,11 -4,16 1,6 3,11 8,15 5,3 11,4 16,4 6,-1 11,-4 15,-9 3,-5 4,-11 4,-16z"/>
                    <path className="fill"
                       d="M154 76c2,1 2,4 1,5 -2,2 -4,2 -5,1 -4,-3 -6,-6 -7,-10 0,-5 1,-9 3,-12 3,-4 7,-6 11,-7 4,0 8,1 12,3 1,1 2,4 0,5 -1,2 -3,2 -5,1 -2,-1 -4,-2 -6,-1 -2,0 -4,1 -6,3 -1,2 -2,4 -1,6 0,3 1,5 3,6z"/>
                </Anime>
                <Anime
                    key={Date.now() + '_saving_pork_ogon'}
                    translateX={[
                        {value: 0, delay: 0},
                        {value: -30, delay: 300, duration: 600},
                    ]}
                    translateY={[
                        {value: 0, delay: 0},
                        {value: 5, delay: 300, duration: 600},
                    ]}
                    scale={[
                        {value: 1, delay: 0},
                        {value: .7, delay: 300, duration: 600},
                    ]}
                    autoplay={anime}
                    loop={true}
                    direction={'alternate'}
                    transformOrigin={`100% 50%`}
                    easing={`easeOutElastic`}
                >
                    <path className="fill"
                       d="M104 205c-2,2 -5,4 -7,5 -3,2 -5,4 -8,5 -2,1 -5,2 -7,2 -1,1 -3,1 -4,1 -1,0 -1,0 -1,0 -2,1 -3,2 -4,2 -2,1 -3,2 -4,2 -2,1 -3,1 -5,2 -1,0 -3,1 -4,1 -1,0 -3,1 -5,1 -1,1 -3,1 -4,1 -2,1 -2,1 -3,1 -1,0 -2,1 -2,1l-2 -7c1,0 2,-1 2,-1 2,0 3,-1 4,-1 1,0 2,0 3,-1 2,0 3,0 5,-1 1,0 3,-1 4,-1 1,-1 3,-1 4,-2l0 0c-1,0 -2,-1 -3,-2 -1,-1 -2,-2 -3,-3 0,-1 -1,-3 -1,-4 0,-1 0,-3 0,-4 0,-1 1,-3 1,-4 1,-1 2,-2 2,-4 1,-1 2,-2 3,-2 1,-1 2,-2 4,-3 1,0 3,-1 5,-1 2,0 3,0 5,0 2,1 3,2 5,3 1,1 2,2 3,4 1,1 2,2 2,3 0,2 0,3 0,4 0,1 0,2 0,3 0,1 0,1 -1,2 2,-1 3,-1 5,-3 2,-1 4,-3 7,-5l4 6zm-31 5c0,0 2,0 3,1 0,-1 0,-1 0,-1 1,0 2,-1 3,-2 0,0 1,-1 2,-2 0,0 0,-1 1,-1 0,-1 0,-1 0,-2 0,0 0,-1 0,-1 0,-1 0,-1 0,-2 0,0 0,-1 -1,-1 0,-1 -1,-2 -2,-3 0,0 -1,0 -2,-1 -1,0 -1,0 -2,0 -1,0 -2,1 -3,1 -1,0 -2,1 -2,1 -1,1 -1,1 -2,2 0,1 -1,1 -1,2 0,1 -1,2 -1,2 0,1 0,1 0,2 0,0 1,1 1,1 0,1 1,1 1,2 1,0 1,1 2,1 1,0 2,1 3,1z"/>
                </Anime>
                <Anime
                    key={Date.now() + '_saving_monay_one'}
                    translateY={[
                        {value: 30, delay:0, duration: 600},
                        {value: 0, delay:0, duration: 400},
                        {value: 0, delay:800, duration: 600},
                    ]}
                    autoplay={anime}
                    direction={'alternate'}
                    easing={`easeInOutQuart`}
                >
                    <path className="fill"
                       d="M184 66c8,0 16,3 21,8 5,6 8,13 8,21 0,8 -3,15 -8,20 -5,6 -13,9 -21,9 -8,0 -15,-3 -20,-9 -5,-5 -9,-12 -9,-20 0,-8 4,-15 9,-21 5,-5 12,-8 20,-8z"/>
                    <path id="fill_0" className="fill_primary"
                       d="M200 79c-4,-3 -10,-6 -16,-6 -6,0 -11,3 -15,6 -4,4 -6,10 -6,16 0,6 2,11 6,15 4,4 9,6 15,6 6,0 12,-2 16,-6 4,-4 6,-9 6,-15 0,-6 -2,-12 -6,-16z"/>
                    <path className="fill"
                       d="M184 103c2,0 4,2 4,4 0,2 -2,4 -4,4 -4,0 -8,-2 -11,-5 -3,-3 -5,-7 -5,-11 0,-5 2,-9 5,-12 3,-2 7,-4 11,-4 2,0 4,1 4,3 0,2 -2,4 -4,4 -2,0 -4,1 -6,3 -1,1 -2,3 -2,6 0,2 1,4 2,6 2,2 4,2 6,2z"/>
                </Anime>
                <Anime
                    key={Date.now() + '_saving_ear'}
                    rotate={-10}
                    autoplay={anime}
                    duration={200}
                    direction={'alternate'}
                    easing={`easeInOutQuart`}
                    loop={true}
                >
                    <path className="fill"
                       d="M210 151c4,-10 7,-16 12,-21 5,-5 11,-7 21,-6l4 1 0 3c-1,7 -2,14 -3,21 0,7 -1,14 -2,21l-7 -1c1,-7 1,-14 2,-21 1,-5 1,-11 2,-17 -6,0 -9,2 -12,4 -4,4 -6,10 -10,18l-7 -2z"/>
                </Anime>
                <path className="fill"
                   d="M162 160c-2,1 -4,1 -5,-1 -1,-2 0,-4 2,-5 6,-3 14,-5 22,-5 8,0 16,2 26,5 2,0 3,2 2,4 -1,2 -3,3 -5,3 -8,-3 -16,-5 -23,-5 -7,0 -14,2 -19,4z"/>
                <Anime
                    key={Date.now() + '_saving_eye'}
                    scaleY={[
                        {value: 0, delay: 0, duration: 200},
                        {value: 1, delay: 0, duration: 200},
                        {value: 0, delay: 1000, duration: 200},
                    ]}
                    autoplay={anime}
                    direction={`normal`}
                    loop={true}
                >
                    <circle className="fill" cx="234" cy="186" r="8"/>
                </Anime>
                <path className="fill"
                   d="M180 0c49,0 94,20 127,53 32,32 53,77 53,127 0,49 -21,94 -53,127 -33,32 -78,53 -127,53 -50,0 -95,-21 -127,-53 -33,-33 -53,-78 -53,-127 0,-50 20,-95 53,-127 32,-33 77,-53 127,-53zm118 62c-31,-30 -72,-49 -118,-49 -46,0 -88,19 -118,49 -30,30 -49,72 -49,118 0,46 19,87 49,118 30,30 72,48 118,48 46,0 87,-18 118,-48 30,-31 48,-72 48,-118 0,-46 -18,-88 -48,-118z"/>
            </g>
        </svg>
    )
}
