import React from 'react';
import styled from 'styled-components';
import { Col, Row, Container } from "react-bootstrap";
import DecorateImage from "../images/DecorateImage";
import { ContentText } from '../typography/ContentText';
import media from './../../theme/mediaQuery';

const SinglePageContainerDiv = styled(Container)`
  min-height: calc(100vh - ${props => props.theme.sizes.nav.height} - ${props => props.theme.sizes.footer.height});
`;
const HeaderRow = styled(Row)`
  height: auto;
`;
const IntroduceCol = styled(Col)`
  padding-top: 3rem;
  padding-right: 100px;
  @media ${media.md} {
    padding-right: 0;  
  }
`;
const Content = styled(Col)`
  padding-top: 100px;
  padding-bottom: 100px;
`;

const SinglePageContainer = ({children, image, introduce, fullImage, decorate = true}) => {
    return (
        <SinglePageContainerDiv>
            <HeaderRow>
                <IntroduceCol lg={6}>
                    { introduce }
                </IntroduceCol>
                <Col lg={6}>
                    <DecorateImage
                        accentSettings={{marginSize: -80, widthScale: .2, heightScale: 1.4}}
                        image={image}
                        decorate={decorate}
                        fullImage={fullImage}
                    />
                </Col>
            </HeaderRow>
            {
                children && (
                    <Row>
                        <Content>
                            <ContentText>
                                {children}
                            </ContentText>
                        </Content>
                    </Row>
                )
            }
        </SinglePageContainerDiv>
    );
};

export default SinglePageContainer;
