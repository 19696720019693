import React from 'react';
import { AccentComponent } from "../typography/Accent.component";
import {Col, Row} from "react-bootstrap";
import { LightSpaceText } from "../typography/LightSpaceText";

export const PhoneComponent = ({callMe, phoneNumber, btnClick, btnText}) => {

    return (
        <Row>
            <Col xs={12}>
                <LightSpaceText>{ callMe }</LightSpaceText>
            </Col>
            <Col xs={12}>
                <AccentComponent>
                    <a href={ "tel:"+phoneNumber }>{phoneNumber}</a>
                    <span
                        onClick={ btnClick }
                    >
                        { btnText }
                    </span>
                </AccentComponent>
            </Col>
        </Row>
    );
};
