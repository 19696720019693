import React from 'react';
import aboutme from '../assets/images/about/about-me.png';
import SinglePageContainer from '../components/container/SinglePageContainer';
import { AboutComponent } from '../components/introduction/About.component';
import {useTranslation} from "react-i18next";
import Helmet from "../components/helmet/helmet";
import withGACurrentPageview from "../hoc/withGACurrentPageview";

import useTimeRangeInYears from '../hooks/useTimeRangeInYears';

const AboutScreen = () => {

    const { t } = useTranslation();

    const aboutMeIntroduce = t('aboutme.introduce', { returnObjects: true, working_from_years: useTimeRangeInYears(new Date(2012,6,1)) });
    const helmet = t('aboutme.helmet', { returnObjects: true });

    return (
        <>
            <Helmet helmet={helmet} />
            <SinglePageContainer
                introduce={<AboutComponent>{aboutMeIntroduce}</AboutComponent>}
                image={aboutme}
                title={`O mnie`}
                intro
            />
        </>
    );
};

export default withGACurrentPageview(AboutScreen);
