import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';

const SocialLink = styled.a`
  float: right;
  & > svg{
    margin: .4rem .4rem 0 0;
    transition: .2s all ease-in;
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }
  &:hover > svg{
    path{
      fill: ${props => props.theme.colors.secondary};
    }
  }
`;

export const SocialsComponent = () => {

    const { t } = useTranslation();
    const socials = t('contact.socials', { returnObjects: true });

    return socials.map(social => (
            <SocialLink href={social.link} target={`_blank`} key={social.link}>
                <FontAwesomeIcon size="lg" icon={fab[social.icon]} />
            </SocialLink>
        )
    );
};