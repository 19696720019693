import React from 'react';
import styled from 'styled-components';
import media from '../../theme/mediaQuery';
import Anime from "react-anime";

const Container = styled.div`
  background: white;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 3px;
  text-align: center;
  display: block;
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  opacity: 0;
  @media ${media.sm} {
    left: 30px;
  }
  > div{
    background: radial-gradient(
      ellipse at 30% 30%,
      ${props => props.theme.colors.primary} 0%,
      ${props => props.theme.colors.darkPrimary} 100%
    );
    height: ${props => props.size * .7}px;
    width: ${props => props.size * .7}px;
    margin-top: ${props => props.size * .15}px; 
    margin-left: ${props => props.size * .15}px; 
    border-radius: 6px;
    > span{
      display: block;
      width: 100%;
      height: 100%;
      transform: rotate(-45deg);
      line-height: ${props => props.size * .7}px;
      text-align: center;
      font-weight: 900;
      color: ${props => props.theme.colors.white};
    }
  }
`;

const StepNumber = ({ number, right, AnimeProps, isVisible }) => {
    return (
        <Anime
            key={Date.now() + 'step_number_' + number}
            opacity={1}
            autoplay={isVisible}
            {...AnimeProps}
        >
            <Container size={30} right={right}>
                <div>
                    <span>{ number }</span>
                </div>
            </Container>
        </Anime>
    );
};

export default StepNumber;