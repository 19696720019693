import React, { useRef, useEffect, useState } from "react";
import { Stage, Layer } from "react-konva";
import Particle from "./particle";
import createParticles from "./services/createParticles";

export const Particles = ({ width, height, color, pos, padding = 9, particleSize = 7, margin = 30 }) => {

    const stageRef = useRef();
    const particles = createParticles( width, height, padding, particleSize, margin, color );
    const [mousePos, setMousePos] = useState({x: 0, y: 0});

    const onMouseMoveHandle = (e) => {
        const pointerPos = e.currentTarget.getPointerPosition();
        if (pointerPos !== mousePos) {
            setMousePos(pointerPos);
        }
    };

    useEffect(() => {
        const stageObj = stageRef.current;
        const con = stageObj.container();
        con.addEventListener('mouseout', () => {
            stageObj.setPointersPositions({x: -900, y: -900});
        });
    }, []);

    return (
        <Stage 
            onMouseMove={onMouseMoveHandle}
            width={width} 
            height={height}
            ref={stageRef}
        >
            <Layer>
                {particles.map(props => (
                    <Particle {...props} key={props.id} mousePos={mousePos} pos={pos} />
                ))}
            </Layer>
        </Stage>
    );
};

export default Particles;
