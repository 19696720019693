import React from 'react';
import Anime from "react-anime";
import {withTheme} from "styled-components";

const HouseCredit = ({ anime, theme }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.1922mm" height="43.1922mm" viewBox="0 0 4278 4278">
             <g id="Warstwa_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer"/>
                 <Anime
                     key={Date.now() + '_house_credit_komin'}
                     fill={[
                         {value: theme.colors.secondary, duration: 1, easing: `linear`, delay: 0},
                         {value: theme.colors.primary, duration: 600, easing: `linear`, delay: 0},
                     ]}
                     translateY={[
                         {value: 300, duration: 1, easing: `linear`, delay: 0 },
                         {value: -600, duration: (e, i) => (i+1) * 600, easing: `linear`, delay: 0 }
                     ]}
                     autoplay={anime}
                     duration={1200}
                     direction={`normal`}
                     loop={true}
                 >
                     <path className={`fill_primary off_hover_fill`} d="M2785 453c-43,-28 -11,-75 22,-66 0,-28 54,-37 76,-9 0,-38 65,-38 65,0 33,-28 87,9 65,37 33,10 22,57 0,57 33,9 22,56 0,56 22,28 -32,65 -65,28 -11,37 -76,37 -76,0 -22,28 -65,19 -54,-9 -33,9 -55,-29 -33,-47 -22,0 -33,-47 0,-47z"/>
                     <path className={`fill_primary off_hover_fill`} d="M2733 694c-43,-28 -11,-75 22,-65 0,-28 54,-38 76,-10 0,-37 65,-37 65,0 33,-28 87,10 66,38 32,9 21,56 0,56 32,10 21,56 0,56 21,29 -33,66 -66,29 -11,37 -76,37 -76,0 -21,28 -65,18 -54,-10 -33,10 -54,-28 -33,-47 -21,0 -32,-47 0,-47z"/>
                 </Anime>
                 <path className={`fill`} d="M2139 0c591,0 1125,239 1512,627 388,387 627,921 627,1512 0,591 -239,1125 -627,1512 -387,388 -921,627 -1512,627 -591,0 -1125,-239 -1512,-627 -388,-387 -627,-921 -627,-1512 0,-591 239,-1125 627,-1512 387,-388 921,-627 1512,-627zm1400 739c-358,-359 -853,-581 -1400,-581 -547,0 -1042,222 -1400,581 -359,358 -581,853 -581,1400 0,547 222,1042 581,1400 358,359 853,581 1400,581 547,0 1042,-222 1400,-581 359,-358 581,-853 581,-1400 0,-547 -222,-1042 -581,-1400z"/>
                 <path className={`fill`} d="M687 1762l1423 -1034c18,-13 41,-12 58,1l432 313 0 -143c0,-52 21,-100 56,-134l0 0c38,-36 83,-57 135,-57l113 0c52,0 100,22 134,57l1 0c34,35 56,82 56,134l0 503 495 360c22,16 35,39 39,63 4,25 -2,51 -17,72l-117 161c-16,22 -39,35 -64,39l-2 0c-24,3 -49,-2 -69,-17l-1221 -888 -1221 888c-22,15 -48,21 -72,17l0 0 0 0c-25,-4 -48,-17 -63,-39l-118 -161c-15,-21 -21,-47 -17,-72 4,-24 17,-47 39,-63zm1452 -933l-1392 1012 114 157 1248 -907c17,-12 41,-13 59,0l1248 907 114 -157 -512 -372c-13,-9 -22,-25 -22,-42l0 -528c0,-25 -11,-48 -27,-64l0 -1c-17,-16 -40,-26 -65,-26l-113 0c-25,0 -48,10 -65,26l0 1c-17,16 -27,39 -27,64l0 240 0 0c0,10 -3,21 -10,29 -16,22 -47,27 -69,11l-481 -350z"/>
                 <path className={`fill`} d="M2996 2097c0,-27 22,-49 49,-49 27,0 50,22 50,49l0 985c0,92 -38,175 -98,235 -60,61 -143,98 -235,98l-1258 0c-91,0 -174,-37 -235,-98 -60,-60 -97,-143 -97,-235l0 -985c0,-27 22,-49 49,-49 28,0 50,22 50,49l0 985c0,65 26,123 68,165 43,43 101,69 165,69l1258 0c64,0 123,-26 165,-69 42,-42 69,-100 69,-165l0 -985z"/>
                 <path className={`fill`} d="M1929 2561l409 0c25,0 49,11 66,28l0 0c17,17 28,41 28,66l0 710 0 50 -50 0 -498 0 -49 0 0 -50 0 -710c0,-25 10,-49 27,-66l0 0c17,-17 41,-28 67,-28zm404 99l-399 0 0 656 399 0 0 -656z"/>
                 <path className={`fill`} d="M1595 2023l207 0c24,0 45,10 61,25 16,16 25,38 25,62l0 206c0,24 -9,45 -25,61 -16,16 -37,25 -61,25l-207 0c-23,0 -45,-9 -61,-25 -15,-16 -25,-37 -25,-61l0 -206c0,-24 10,-46 25,-62 16,-15 38,-25 61,-25z"/>
                 <path className={`fill`} d="M2465 2023l206 0c24,0 45,10 61,25 16,16 25,38 25,62l0 206c0,24 -9,45 -25,61 -16,16 -37,25 -61,25l-206 0c-24,0 -46,-9 -62,-25 -15,-16 -25,-37 -25,-61l0 -206c0,-24 10,-46 25,-62 16,-15 38,-25 62,-25z"/>
                 <Anime
                     key={Date.now() + '_house_credit_windows'}
                     fill={[
                         {value: theme.colors.secondary, duration: 10}
                     ]}
                     delay={0}
                     duration={9999999999}
                     autoplay={anime}
                     direction={`normal`}
                     loop={false}
                 >
                     <path className={`fill_primary off_hover_fill`} d="M2671 2097l-206 0c-4,0 -7,2 -9,4 -2,2 -4,5 -4,9l0 206c0,3 2,6 4,8 2,3 5,4 9,4l206 0c3,0 6,-1 8,-4 3,-2 4,-5 4,-8l0 -206c0,-4 -1,-7 -4,-9 -2,-2 -5,-4 -8,-4z"/>
                     <path className={`fill_primary off_hover_fill`} d="M1802 2097l-207 0c-3,0 -6,2 -8,4 -2,2 -4,5 -4,9l0 206c0,3 2,6 4,8 2,3 5,4 8,4l207 0c3,0 6,-1 8,-4 3,-2 4,-5 4,-8l0 -206c0,-4 -1,-7 -4,-9 -2,-2 -5,-4 -8,-4z"/>
                 </Anime>
             </g>
        </svg>
    );
};

export default withTheme(HouseCredit);