import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SinglePageTitle } from '../typography/SinglePageTitle';
import { ContentText } from '../typography/ContentText';
import ContactInfoComponent from "../contactInfo/ContactInfo.component";
import { LineSeparator } from '../content/LineSeparator';

export const PageComponent = ({title, introduceText}) => {
    return (
        <Row>
            <Col>
                <SinglePageTitle>{ title }</SinglePageTitle>
                <LineSeparator />
                <ContentText>{ introduceText }</ContentText>
                <ContactInfoComponent/>
            </Col>
        </Row>
    );
};
