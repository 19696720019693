import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SingleWizardSection from "./SingleWizardSection";
import SectionTitle from "../typography/SectionTitle";
import SectionContainer from "../section/SectionContainer";
import {useTranslation} from "react-i18next";
import SectionTitleDesc from "../typography/SectionTitleDesc";

const WizardSection = () => {

    const { t } = useTranslation();
    const plan = t('sections.workPlan.plan', { returnObjects: true });

    return (
        <SectionContainer>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={10} md={10} sm={12} col={9}>
                        <SectionTitle>
                            { t("sections.workPlan.title") }
                            <SectionTitleDesc>{ t("sections.workPlan.subtitle") }</SectionTitleDesc>
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            Object.keys(plan).map((planKey, i) => (
                                <SingleWizardSection
                                    key={plan[planKey].title}
                                    stepNumber={planKey}
                                    right={(planKey - 1) % 2}
                                    isLast={i === Object.keys(plan).length - 1}
                                    offsetAnimeTop={150}
                                    {...plan[planKey]}
                                />
                            ))
                        }
                    </Col>
                </Row>
            </Container>
        </SectionContainer>
    );
};

export default WizardSection;