import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import ServiceIcon from "./ServiceIcon";
import ServiceIconSVG from './../../assets/icons/services_icons';
import {useTranslation} from "react-i18next";

const ServicesIconsContainer = styled.div`
  display: flex;
  align-items: baseline;
  align-content: center;
  justify-content: space-around;
  flex-flow: row wrap;
  padding: 2rem 5rem;
  margin-top: 5rem;
`;

const ServicesIcons = withRouter(({history}) => {

    const makeIcon = (service, icon = null) => ({
        title: service.title,
        onClick: () => history.push(`/uslugi/${service.slug}`),
        icon: icon
    });

    const { t } = useTranslation();
    const servicesData = t( "sections.services.data", { returnObjects: true });
    const services = servicesData.map(s => makeIcon(s, ServiceIconSVG[s.icon], ));
    return (
        <ServicesIconsContainer>
            { services.map(service => (
                <ServiceIcon {...service} key={service.title} />
            ))}
        </ServicesIconsContainer>
    );
});

export default ServicesIcons;