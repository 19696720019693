import React, { useEffect, useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, InputGroup} from "react-bootstrap";
import styled from "styled-components";
import { Transition } from "react-transition-group"
import {statuses} from "./LeaveNumberInput";

const Append = styled(InputGroup.Append)`
  overflow: hidden;
  & > button {
    transition: all .3s ease-in-out;
    transform: translateX(-50px);
    opacity: 0;
  }
  & > button {
    opacity: ${({ state, status }) => (
        (state === "entering" || state === "entered") && status !== statuses.FETCHING ? 0 : 1
    )};
    transform: translate(
        ${({ state, status }) => (
            (state === "entering" || state === "entered") && status !== statuses.FETCHING ? `-50px` : 0
        )}
    ); 
  }
`;
const ConfirmBtn = styled(Button)`
  height: 43px; 
  border: none;
  font-size: 1.2rem;
  border-radius: 0; 
  background: ${props => props.status === statuses.SAVED ? props.theme.colors.primary : props.theme.colors.darkSecondary};
  color: ${props => props.theme.colors.white};
  box-shadow: none;
  &:hover{
    background: ${props => props.theme.colors.darkPrimary};
  }
`;

const LeaveNumberBtn = ({onClick, status, icon}) => {

    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false)
        }, 300)
    }, [status]);

    return (
        <Transition in={animate} timeout={300}>
            {(state) => (
                <Append state={state} status={status}>
                    <ConfirmBtn status={status} onClick={onClick} variant="outline-secondary">
                        <FontAwesomeIcon icon={icon} spin={status === statuses.FETCHING}/>
                    </ConfirmBtn>
                </Append>
            )}
        </Transition>
    );
};

export default LeaveNumberBtn;