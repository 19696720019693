import React, { useRef, useState, useEffect } from 'react';
import {Col, Row as BaseRow} from "react-bootstrap";
import {LightSpaceText as BaseLightSpaceText} from '../typography/LightSpaceText';
import styled, {withTheme} from 'styled-components';
import Accents from "./Accents";
import media from '../../theme/mediaQuery';

const LightSpaceText = styled(BaseLightSpaceText)`
  text-align: right;
  padding-top: 7px;
  @media ${media.sm} {
      text-align: left;
  }
`;

const Row = styled(BaseRow)`
  padding-bottom: 1.2rem;
  margin-top: ${props => props.accent ? '3rem' : '1.2rem'};
  position: relative;
  > div:nth-child(2){
    height: ${props => props.height ? props.height : 'auto'};
  }
`;

export const ContactRow = withTheme(({theme, title, children, accent, height = null}) => {

    const ElementRef = useRef(null);
    const [element, setElement] = useState(null);

    useEffect(() => {
        setElement(ElementRef.current);
    }, []);


    return (
        <Row
            accent
            height={height}
        >
            <Col sm={2}>
                <LightSpaceText>{ title }</LightSpaceText>
            </Col>
            <Col sm={{span: 9, offset: 1}}
                 ref={ElementRef}
            >
                {
                    accent && (
                        <Accents
                            parent={element}
                            position={['top-left']}
                            color={theme.colors.darkSecondary}
                            heightScale={1.2}
                            widthtScale={2}
                            marginSize={-55}
                        />
                    )
                }
                { children }
            </Col>
        </Row>
    );
});
