import React from 'react';
import SectionContainer from "../section/SectionContainer";
import SectionTitle from '../typography/SectionTitle';
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import AccentTextBox from "../content/AccentTextBox";
import ServicesIcons from "./ServicesIcons";

const ServicesSection = () => {

    const { t } = useTranslation();

    return (
        <SectionContainer>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={10} md={10} sm={12} col={9}>
                        <SectionTitle>
                            { t("sections.services.title") }
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <AccentTextBox
                            title={`NIC NIE ZAPŁACISZ`}
                            subtitle={`Całkowicie za darmo`}
                        >
                            <p>
                                Sprawdzę dla Ciebie wszystkie możliwości, dopasuje je do Twoich potrzeb
                                i dzięki wieloletniemu doświadczeniu przygotuję Cię na każdą ewentualność.
                            </p>
                            <p>
                                Poprowadzę Cię przez każdy etap kredytowania, inwestycji czy wnioskowania o ubezpeczenie.
                            </p>
                        </AccentTextBox>
                    </Col>
                    <Col lg={8}>
                        <ServicesIcons />
                    </Col>
                </Row>
            </Container>
        </SectionContainer>
    );
};

export default ServicesSection;