import React from 'react';
import { useTranslation } from 'react-i18next';
import { servicesImages } from "../assets/images/services";
import SinglePageContainer from "../components/container/SinglePageContainer";
import { PageComponent } from "../components/introduction/Page.component";
import { ContentText } from "../components/typography/ContentText";
import Helmet from "../components/helmet/helmet";
import withGACurrentPageview from "../hoc/withGACurrentPageview";


const ContactScreen = ({ match }) => {

    const { t } = useTranslation();
    const servicesData = t('sections.services.data', { returnObjects: true });
    const service = servicesData.filter(s => s.slug === match.params.slug)[0];
    const image = servicesImages[match.params.slug];
    const helmet = service.helmet;

    return (
        <SinglePageContainer
            introduce={
                <PageComponent
                    title={service.title}
                    introduceText={service.introduce}
                />
            }
            image={image}
            imageFull={false}
        >
            <Helmet helmet={helmet} />
            <div>
                { service.text.map((t, key) => <ContentText key={key}>{ t }</ContentText>) }
            </div>
        </SinglePageContainer>
    );
};

export default withGACurrentPageview(ContactScreen);
