import React from 'react';
import {withRouter} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap'
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import styled from 'styled-components';
import nav_logo from '../../assets/images/logo/logo.svg';
import media from '../../theme/mediaQuery';
import {useTranslation} from "react-i18next";

const NavigationContent = styled(Navbar)`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  padding: 0;
  min-height: ${props => props.theme.sizes.nav.height};
`;

const Collapse = styled(Navbar.Collapse)`
  justify-content: flex-end;
`;

const NavWrapper = styled(Container)`
  @media ${media.md} {
      padding-top: 15px;
      padding-bottom: 15px;
  }
  > a.navbar-brand{
    margin: 0 1rem;
  }
  > button.navbar-toggler {
    border: 0;
    width: 60px;
    height: 40px;
    position: relative;
    padding: .25rem 1rem;
    &:focus{
      border: 0;
      outline: 0;
    }
    > span{
      background-color: ${props => props.theme.colors.darkPrimary};
      display: block;
      height: 2px;
      width: 100%;
      float: right;
      transition: .2s all ease-in-out;
      &:nth-child(2) {
        width: 76%;
        margin-top: 6px;
      }
      &:nth-child(3) {
        width: 50%;
        margin-top: 6px;
      }
    }
    &:hover > span{
      &:nth-child(2),
      &:nth-child(3) {
        width: 100%;
      }
    }
    &:not(.collapsed) > span{
      transform: translateY(8px) rotate(45deg);
      width: 100%!important; 
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }
`;

const LinksWraper = styled.div`
  display: flex;
  @media ${media.md} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  & > .nav-link, 
  & > .nav-item {
      font-size: 1rem;
      font-weight: 600;
      padding: 0 1.4rem 0 1.4rem!important; 
      height: ${props => props.theme.sizes.nav.height};
      line-height: ${props => props.theme.sizes.nav.height};
      color: ${props => props.theme.colors.primary}!important;
      margin-bottom: -1px;
      @media ${media.md} {
        width: auto;
        height: auto;
        line-height: 2.2rem;
        font-size: 2rem;
        text-align: center;
        margin: 0.4rem 0;
        & > .nav-item {
          padding: 2rem 0;
        }
      }
      &:hover, &.active{
        color: ${props => props.theme.colors.darkPrimary}!important;  
      }
      &.active{  
        border-bottom:  3px solid ${props => props.theme.colors.secondary};
      }
      &.uslugi{
        ${props => props.pathname.match(/uslugi/g) ? 'border-bottom:  3px solid ' + props.theme.colors.secondary + ';' : null}
      }
  }
  & > .nav-item > a {
    color: ${props => props.theme.colors.primary}!important;
    padding-top: 0;
    padding-bottom: 0;
    &:hover{
      color: ${props => props.theme.colors.darkPrimary}!important;  
    }
  }
  & > .nav-item.dropdown{
      a::after{
        display: none;
      }
    .dropdown-menu{
     margin: 0;
     padding: .3rem 0;
     border-radius: 0;
     border: 0;
     box-shadow: 0 5px 5px rgba(0,0,0,.1);
     @media ${media.md} {
        box-shadow: none!important;
     }
     & .dropdown-item{
      line-height: 1.2rem;
      @media ${media.md} {
        padding: .8rem 0;
        font-size: 1.4rem;
        text-align: center;
      }
      &.active, &:focus, &:active, &:hover{
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
      }
     }
     & div.dropdown-divider{
      border-top: 2px solid ${props => props.theme.colors.secondary};
      margin: .3rem 1rem;
     }
    }
  }
`;

const Logo = styled.img`
  height: calc(${props => props.theme.sizes.nav.height} - 40px);
`;

const NavPhone = styled(Nav.Link)`
  height: 34px!important;
  line-height: 34px!important;
  margin-top: 23px;
  background-color: ${props => props.theme.colors.secondary};
  margin-left: 60px;
  @media ${media.md} {
      margin-left: 0;
      height: 50px!important;
      line-height: 50px!important;
      margin-top: 30px;
  }
`;

const NavContainer = (props) => {

    const {t} = useTranslation();
    const servicesSlug = t('sections.services.slug');
    const servicesData = t('sections.services.data', { returnObjects: true });
    const phoneNumber = t('contact.phoneNumber');

    return (
        <NavigationContent collapseOnSelect expand="lg" fixed="top">
            <NavWrapper
                pathname={props.location.pathname}
            >
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <Logo src={nav_logo} alt="Agnieszka Łuczak"/>
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    <span className={`navbar-toggle-bar`}/>
                    <span className={`navbar-toggle-bar`}/>
                    <span className={`navbar-toggle-bar`}/>
                </Navbar.Toggle>
                <Collapse id="responsive-navbar-nav">
                    <Nav>
                        <LinksWraper
                            pathname={props.location.pathname}
                        >
                            <Nav.Link onClick={() => props.history.push('/')} active={props.location.pathname === '/'} >Home</Nav.Link>
                            <NavDropdown className={`uslugi`} title="Usługi" id="basic-nav-dropdown">
                                {
                                    servicesData.map(s => (
                                        <div key={s.slug}>
                                            <LinkContainer to={`/${servicesSlug}/${s.slug}`}>
                                                <NavDropdown.Item>{ s.title }</NavDropdown.Item>
                                            </LinkContainer>
                                            <NavDropdown.Divider />
                                        </div>
                                    ))
                                }
                            </NavDropdown>
                            <LinkContainer to="/porownywarka-kredytow">
                                <Nav.Link active={props.location.pathname === '/porownywarka-kredytow'} >Porównywarka kredytów</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/o-mnie">
                                <Nav.Link active={props.location.pathname === '/o-mnie'} >O mnie</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/kontakt">
                                <Nav.Link active={props.location.pathname === '/kontakt'}>Kontakt</Nav.Link>
                            </LinkContainer>
                            <NavPhone href={`tel:${phoneNumber}`}>
                                tel. {phoneNumber}
                            </NavPhone>
                        </LinksWraper>
                    </Nav>
                </Collapse>
            </NavWrapper>
        </NavigationContent>
    );
};

export default withRouter(NavContainer);
