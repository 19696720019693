import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import styled from 'styled-components';

export const WidgetHost = 'https://www.lendi.pl/lendigety/LightComparerWidget';

export const WidgetParentLocation = ''

export const WidgetDefaultSettings = {
    primaryColor: '#331E56',
    secondaryColor: '#6A5095',
    dark: false,
    agentId: 14146,
    utm_owner: 14146,
    utm_fp_source_id: 348,
    utm_fp_partner_id: 837,
};

export const WidgetsNames = {
    LIGHT_COMPARER_WIDGET: 'LightComparerWidget',
    BASE_INSTALLMENT_PICKER: 'BaseInstallmentPicker',
    BI_COMPARER_WIDGET: 'BIComparerWidget',
    BUSINESS_INSIDER_WIDGET: 'BusinessInsiderWidget',
    NAVIBOX_BANNER: 'NaviboxBanner',
    WIDEBOARD_BANNER: 'WideboardBanner',
    HALF_PAGE_BANNER: 'HalfPageBanner',
    COMPARER_WIDGET: 'ComparerWidget',
    MEETING_PLANNER_WIDGET: 'MeetingPlannerWidget',
};

const Loader = styled.div`
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    text-align: center;
    top: 0;
    left: 0;
    line-height: 100vh;
    font-size: 1.45rem;
`

export default ({widgetName, widgetSettings, widgetProps}) => {

    const widgetUrl = `${WidgetHost}`;
    const queryParams = new URLSearchParams({...WidgetDefaultSettings, ...widgetSettings}).toString();
    const src = useCallback(() => `${widgetUrl}?&${queryParams}`, []);
    
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            { !loaded && ( <Loader>To potrwa tylko chwilę...</Loader> )}
            <iframe
                name="Lendi widget"
                title="Lendi Embedded widget"
                widget={widgetName}
                src={src()}
                border="none"
                scrolling="no"
                loading="lazy"
                width="100%"
                frameborder="0"
                {...widgetProps}
                height={loaded ? widgetProps.height : 0}
                onLoad={() => setLoaded(true)}
            />
        </>
    );
}