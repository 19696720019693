import React from 'react';
import styled from 'styled-components';
import FooterSocialElement from './FooterSocialElement';
import {useTranslation} from "react-i18next";

const SocialList = styled.ul`
  width: 100%;
  height: 100%;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  li{
    display: inline-block;
    margin-left: 1rem;
    opacity: .5;
    transition: .2s opacity ease-in;
    &:hover{
      opacity: 1;
    }
  }
`;

const FooterSocialContainer = () => {

    const { t } = useTranslation();
    const socials = t('contact.socials', { returnObjects: true });

    return (
        <SocialList>
            {
                socials.map(s => ( <FooterSocialElement key={s.link} social={s} />))
            }
        </SocialList>
    );
};

export default FooterSocialContainer;
