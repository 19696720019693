import styled from 'styled-components';

const Text = styled.p`
  color: ${ props => props.color ? props.color : props.theme.colors.primary };
  font-style: ${props => props.italic ? 'italic' : 'normal'};
  padding: 1vh;
  text-align: center;
  font-size: 1rem;
  line-height: 1.2rem;
`;
export default Text;