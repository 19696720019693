import React from 'react';
import {Col, Row} from "react-bootstrap";
import { SocialsComponent as SocialListComponent } from '../contact/Socials.component';
import {LightSpaceText} from "../typography/LightSpaceText";

export const SocialsComponent = ({ followMe }) => {

    return (
        <Row>
            <Col xs={12}>
                <LightSpaceText right >{ followMe }</LightSpaceText>
            </Col>
            <Col xs={12}>
                <SocialListComponent />
            </Col>
        </Row>
    );
};
