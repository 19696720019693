export default function (endDate, dateFrom = null) {

    const secondsInYear = 31556926;

    if (!dateFrom) {
        dateFrom = Date.now();
    }

    function yearsDiff(d1, d2) {
        let millisecondDiff = d2 - d1;
        let secDiff = Math.floor( millisecondDiff / 1000 );
        return Math.floor(Math.abs(secDiff/secondsInYear));
    }

    return yearsDiff(dateFrom, endDate.getTime());
}