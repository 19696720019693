export default function createParticles(
  width,
  height,
  padding,
  particleSize,
  margin,
  color
) {
  const widthEl = Math.floor((width - 2 * margin) / (particleSize + padding));
  const heightEl = Math.floor((height - 2 * margin) / (particleSize + padding));

  if (widthEl <=0 || heightEl <=0 ) return [];
  return [...Array(widthEl).keys()]
    .map(x => {
      return [...Array(heightEl).keys()].map(y => {
        return {
          x: x * particleSize + x * padding + margin,
          y: y * particleSize + y * padding + margin,
          size: particleSize,
          key: `${x}_${y}`,
          id: `${x}_${y}`,
          fill: color
        };
      });
    })
    .reduce((x, y) => x.concat(y));
}
