import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';


i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        loadPath: '/public/locales/{{lng}}/{{ns}}.json',
        lng: 'pl',
        fallbackLng: 'pl',
        load: 'languageOnly',
        preload: ['pl'],
        debug: false,
        crossDomain: true,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        ns: ["home"],
        defaultNS: "home",
        customHeaders: {
            "Access-Control-Allow-Origin": "*"
        }
    });

export default i18n;
