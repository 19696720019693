import React, { useState } from 'react';
import LightText from '../typography/LightText';
import LeaveNumberInput from "./LeaveNumberInput";
import { Col, Row } from 'react-bootstrap';
import {useTranslation} from "react-i18next";

const HeaderLaveNumber = () => {

    const { t } = useTranslation();
    const initialText = t("header.leavePhone");
    const [text, setText] = useState(initialText);
    const isSelected = () => setText(t("header.receivedPhone"));
    const isUnselected = () => setText(initialText);

    return (
        <>
            <Row>
                <Col>
                    <LightText marginBottom={0}>{ text }</LightText>
                </Col>
            </Row>
            <Row>
                <Col>
                    <LeaveNumberInput
                        setIsSelected={isSelected}
                        setIsUnselected={isUnselected}
                    />
                </Col>
            </Row>
        </>
    );
};

export default HeaderLaveNumber;